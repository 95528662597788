import {
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import Transaction from 'components/dataDisplay/Transaction';
import { formatTableValue } from 'utils/format';
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import fakeGraph from 'assets/img/dashboards/fakeGraph.png';
import {
  LiaClipboardCheckSolid,
  LiaHandshake,
  LiaTagsSolid,
} from 'react-icons/lia';

export default function Balance(props) {
  const { balance, ...rest } = props;

  const blueIcon = useColorModeValue('blue.500', 'white');
  const greenIcon = useColorModeValue('green.500', 'white');
  const yellowIcon = useColorModeValue('yellow.500', 'white');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  // Helper function to handle null or undefined values
  const safeFormatValue = (type, value) => {
    return formatTableValue(type, value ?? 0);
  };

  const total = balance[0]?.pergamino?.total ?? 0;
  const disponible = balance[0]?.pergamino?.disponible ?? 0;
  const oferta = balance[0]?.pergamino?.oferta ?? 0;
  const maquila = balance[0]?.pergamino?.maquila ?? 0;

  const getPercentage = (part, total) => {
    return total > 0 ? ((part / total) * 100).toFixed(2) : '0.00';
  };

  return (
    <Card direction="column" w="100%" {...rest}>
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex flexDirection="column" me="20px">
            <Text color="white" fontSize="sm" fontWeight="500">
              Pergamino seco en inventario
            </Text>
            <Text
              color="white"
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              {safeFormatValue('kilograms', total)}
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            ms="auto"
            justify="space-between"
            align="flex-end"
          >
            <Image src={fakeGraph} w="59px" h="17px" />
          </Flex>
        </Flex>
      </Flex>
      <Text color="secondaryGray.600" fontWeight="500" fontSize="sm" mb="10px">
        Desglose
      </Text>
      <Flex direction="column">
        <Transaction
          mb="20px"
          name="Disponible"
          date={safeFormatValue('percentage', getPercentage(disponible, total))}
          sum={safeFormatValue('kilograms', disponible)}
          icon={
            <Icon
              as={LiaClipboardCheckSolid}
              color={greenIcon}
              w="20px"
              h="18px"
            />
          }
        />
        <Transaction
          mb="20px"
          name="Muestra-oferta"
          date={safeFormatValue('percentage', getPercentage(oferta, total))}
          sum={safeFormatValue('kilograms', oferta)}
          icon={<Icon as={LiaHandshake} color={blueIcon} w="20px" h="18px" />}
        />
        <Transaction
          mb="20px"
          name="Orden de maquila"
          date={safeFormatValue('percentage', getPercentage(maquila, total))}
          sum={safeFormatValue('kilograms', maquila)}
          icon={<Icon as={LiaTagsSolid} color={yellowIcon} w="20px" h="18px" />}
        />
      </Flex>
    </Card>
  );
}
