import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

import { QuestionIcon } from '@chakra-ui/icons';
import { SALIDA } from 'constant/constant';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { capitalizeEveryWord, extractNumericValue } from 'utils/format';

export default function SalidaForm({
  id,
  maquila,
  weightExit,
  isSubmitted,
  handleFocus,
  handleWeightExitChange,
  deleteExit,
  setWeightExits,
}) {
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const handleFolioSelection = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setWeightExits((prevState) => {
      return prevState.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            folioMaquila: e.target.value,
            numeroIco: selectedOption?.dataset?.ico,
            caracteristica: selectedOption?.dataset?.caracteristica,
            estado: selectedOption?.dataset?.estado,
            region: selectedOption?.dataset?.region,
            municipio: selectedOption?.dataset?.municipio,
            comunidad: selectedOption?.dataset?.comunidad,
            productor: selectedOption?.dataset?.productor,
          };
        }
        return item;
      });
    });
  };

  return (
    <FormControl id={id}>
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        mb={'1rem'}
        px={'25px'}
      >
        <Flex alignItems="center" flexDirection="row" me="14px">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {'Registro ' + id}
          </Text>
          <Text
            ms="auto"
            color={textColor}
            fontSize="sm"
            me="6px"
            fontWeight="700"
          ></Text>
        </Flex>
        <Tooltip hasArrow label="Eliminar registro" bg="gray.300" color="black">
          <Button onClick={() => deleteExit(id)}>
            <IoRemoveCircleOutline fontSize="1.5rem" />
          </Button>
        </Tooltip>
      </Flex>
      <Divider orientation="horizontal" my={'2rem'} />
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        mb={'2rem'}
        px={'20px'}
      >
        <Flex alignItems="center" flexDirection="row" me="14px" gap="10px">
          <Text
            color="grey.500"
            fontSize="18px"
            fontWeight="700"
            lineHeight="100%"
          >
            Vehículo o transporte
          </Text>
        </Flex>
      </Flex>
      <SimpleGrid
        columns={{ base: '1', md: '3', lg: '4' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'20px'}
      >
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Nombre del operador (chofer){' '}
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <Input
            borderColor={isSubmitted && !weightExit.chofer ? 'red.500' : null}
            value={weightExit.chofer || ''}
            onChange={(e) =>
              handleWeightExitChange(
                'chofer',
                capitalizeEveryWord(e.target.value),
                id,
              )
            }
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            isRequired={true}
            fontSize="md"
            placeholder="Operador"
            variant="auth"
            size="lg"
          />
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Placas{' '}
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <Input
            borderColor={isSubmitted && !weightExit.placas ? 'red.500' : null}
            value={weightExit.placas || ''}
            onChange={(e) =>
              handleWeightExitChange('placas', e.target.value.toUpperCase(), id)
            }
            borderRadius="6px"
            height="40px"
            isRequired={true}
            fontSize="md"
            placeholder="Placas"
            variant="auth"
            size="lg"
          />
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Marca{' '}
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <Input
            borderColor={isSubmitted && !weightExit.marca ? 'red.500' : null}
            value={weightExit.marca || ''}
            onChange={(e) =>
              handleWeightExitChange(
                'marca',
                capitalizeEveryWord(e.target.value),
                id,
              )
            }
            borderRadius="6px"
            height="40px"
            isRequired={true}
            fontSize="md"
            placeholder="Marca"
            variant="auth"
            size="md"
          />
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Número de unidad{' '}
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <Input
            borderColor={isSubmitted && !weightExit.unidad ? 'red.500' : null}
            value={weightExit.unidad || ''}
            onChange={(e) =>
              handleWeightExitChange('unidad', e.target.value.trim(), id)
            }
            borderRadius="6px"
            height="40px"
            isRequired={true}
            fontSize="md"
            placeholder="Número de unidad"
            variant="auth"
            size="md"
          />
        </Flex>
      </SimpleGrid>
      <Flex
        justifyContent={'flex-start'}
        alignItems={'center'}
        my={'2rem'}
        gap={'2rem'}
        px={'20px'}
      >
        <Text fontSize="md" fontWeight="500" color={textColor}>
          Camión en condiciones de sanidad exterior e interior, en piso cubierta
          y techo
        </Text>
        <Stack spacing={5} direction="row">
          <Checkbox
            isChecked={weightExit.cumple === 'Cumple'}
            onChange={(e) => handleWeightExitChange('cumple', 'Cumple', id)}
            color={textColor}
          >
            Cumple
          </Checkbox>
          <Checkbox
            isChecked={weightExit.cumple === 'No cumple'}
            onChange={(e) => handleWeightExitChange('cumple', 'No cumple', id)}
            color={textColor}
          >
            No cumple
          </Checkbox>
        </Stack>
      </Flex>
      <Divider orientation="horizontal" my={'2rem'} />
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        mb={'2rem'}
        px={'25px'}
      >
        <Flex alignItems="center" flexDirection="row" me="14px" gap="10px">
          <Text
            color="grey.500"
            fontSize="18px"
            fontWeight="700"
            lineHeight="100%"
          >
            Salida de café
          </Text>
        </Flex>
      </Flex>
      <SimpleGrid
        columns={{ base: '1', md: '3', lg: '3' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'20px'}
      >
        <Flex direction="column">
          <Tooltip label={SALIDA.FOLIO_TOOLTIP} aria-label="A tooltip">
            <FormLabel
              display="flex"
              alignItems="center"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              gap="10px"
            >
              Folio de maquila <QuestionIcon color={textColor} />
            </FormLabel>
          </Tooltip>
          <Select
            borderColor={
              isSubmitted && !weightExit.folioMaquila ? 'red.500' : null
            }
            value={weightExit.folioMaquila}
            onChange={(e) => {
              handleFolioSelection(e);
            }}
            onFocus={handleFocus}
            variant="auth"
            placeholder="Folio de maquila"
          >
            {maquila?.length > 0 &&
              maquila.map((x) => (
                <option
                  key={x.id}
                  value={x.id}
                  data-ico={x.numeroIco || 'No especificado'}
                  data-caracteristica={x.caracteristica || 'No especificado'}
                  data-estado={x.estado || 'No especificado'}
                  data-region={x.region || 'No especificado'}
                  data-municipio={x.municipio || 'No especificado'}
                  data-comunidad={x.comunidad || 'No especificado'}
                  data-productor={x.productor || 'No especificado'}
                >
                  M-{x.id}
                </option>
              ))}
          </Select>
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Número ICO
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <InputGroup>
            <Input
              borderColor={
                isSubmitted && !weightExit.numeroIco ? 'red.500' : null
              }
              value={weightExit.numeroIco}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightExitChange('numeroIco', value, id);
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Número ICO"
              variant="auth"
              size="lg"
            />
          </InputGroup>
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Característica de café
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <InputGroup>
            <Input
              borderColor={
                isSubmitted && !weightExit.caracteristica ? 'red.500' : null
              }
              value={weightExit.caracteristica}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightExitChange('caracteristica', value, id);
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Característica de café"
              variant="auth"
              size="lg"
            />
          </InputGroup>
        </Flex>
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: '1', md: '3', lg: '4' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'20px'}
      >
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Sacos
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <InputGroup>
            <Input
              borderColor={
                isSubmitted && !weightExit.sacosTotal ? 'red.500' : null
              }
              value={weightExit.sacosTotal}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightExitChange('sacosTotal', value, id);
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Número de sacos"
              variant="auth"
              size="lg"
            />
          </InputGroup>
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Peso bruto total
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <InputGroup>
            <Input
              borderColor={
                isSubmitted && !weightExit.kilosBrutoTotal ? 'red.500' : null
              }
              value={weightExit.kilosBrutoTotal}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightExitChange('kilosBrutoTotal', value, id);
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Peso bruto total"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG</InputRightAddon>
          </InputGroup>
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Peso neto total
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <InputGroup>
            <Input
              borderColor={
                isSubmitted && !weightExit.kilosNetoTotal ? 'red.500' : null
              }
              value={weightExit.kilosNetoTotal}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightExitChange('kilosNetoTotal', value, id);
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Peso neto total"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG</InputRightAddon>
          </InputGroup>
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Precio libra
          </FormLabel>
          <InputGroup>
            <Input
              value={weightExit.precioLibra}
              onChange={(e) =>
                handleWeightExitChange('precioLibra', e.target.value, id)
              }
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Precio por libra"
              variant="auth"
              size="lg"
            />
          </InputGroup>
        </Flex>
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: '1', md: '1', lg: '1' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'20px'}
      >
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Observaciones
          </FormLabel>
          <InputGroup>
            <Input
              value={weightExit.observaciones}
              onChange={(e) =>
                handleWeightExitChange('observaciones', e.target.value, id)
              }
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Observaciones"
              variant="auth"
              size="lg"
            />
          </InputGroup>
        </Flex>
      </SimpleGrid>
      <Divider orientation="horizontal" my={'2rem'} />
    </FormControl>
  );
}
