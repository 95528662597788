import { Button, Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { addRendimiento } from 'api/rendimiento';
import { getAllSecado } from 'api/secado';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { MdOutlineDriveFileMove } from 'react-icons/md';
import { useSelector } from 'react-redux';
import Resultado from './components/Resultado';
import Seleccion from './components/Seleccion';

export default function RendimientoRegistro() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const { origen } = useSelector((state) => state.origen);

  const initialStateRendimiento = {
    folio: '',
    claveDescarga: '',
    entradaId: '',
    estado: '',
    region: '',
    comunidad: '',
    municipio: '',
    productor: '',
    tipo: '',
    caracteristica: '',
    humedad: '',
    actividadAgua: '0.5',
    muestra: '300.00',
    verdeExportable: '',
    inferiores: '',
    verdeTotal: '',
    rendimientoExportable: '',
    rendimientoInferior: '',
    rendimientoTotal: '',
    observaciones: '',
  };

  const [secado, setSecado] = useState([]);
  const [rendimiento, setRendimiento] = useState(initialStateRendimiento);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const fetchAllSecado = async () => {
      try {
        setIsLoading(true);
        const response = await getAllSecado(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData =
            result?.data?.data.filter(
              (x) => x.estatus !== 'salida' && x.estatus !== 'maquila',
            ) || [];
          setSecado(resultData);
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllSecado();
  }, []);

  const handleChange = (field, value) => {
    setRendimiento((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleAllTruthy = (obj, exceptions = []) => {
    const arrayOfObjects = Array.isArray(obj) ? obj : [obj];
    const values = arrayOfObjects.flatMap((item) => {
      const keys = Object.keys(item);
      const filteredKeys = keys.filter((key) => !exceptions.includes(key));
      return filteredKeys.map((key) => item[key]);
    });

    if (!values || values.length === 0) {
      return false;
    } else {
      return values.every((value) => !!value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const exceptions = ['observaciones'];
    const allPropertiesTruthy = handleAllTruthy(rendimiento, exceptions);
    if (allPropertiesTruthy) {
      try {
        setIsLoading(true);
        setIsSubmitted(true);
        const response = await addRendimiento(TOKEN, USERID, rendimiento);
        if (response.status === 200) {
          handleMessage(
            'success',
            'Operación exitosa.',
            'El registro de rendimiento fue guardado correctamente.',
          );
          window.scrollTo({ top: 0, left: 0 });
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSubmitted(true);
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Seleccion
              secado={secado}
              rendimiento={rendimiento}
              setRendimiento={setRendimiento}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              origen={origen}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Resultado
              rendimiento={rendimiento}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleChange={handleChange}
            />
          </Flex>
        </Grid>

        <Flex justify="center" my="40px">
          <Button
            onClick={handleSubmit}
            leftIcon={<MdOutlineDriveFileMove />}
            isLoading={isLoading}
            loadingText="Realizando registro..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Registrar rendimiento
          </Button>
        </Flex>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
