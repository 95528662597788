import {
  Flex,
  Icon,
  SimpleGrid,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  Button,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import Perfil from 'components/card/Perfil';
import FlavorItem from 'components/dataDisplay/FlavorItem';
import { GiCoffeeBeans } from 'react-icons/gi';

export default function SelectionsPanel({
  selectedFlavors,
  setSelectedFlavors,
  isLoading,
  handleSubmit,
}) {
  const iconColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const handlePerfilRendering = (type, item) => {
    let flavor = item ? item : selectedFlavors[selectedFlavors.length - 1];

    switch (type) {
      case 'name': {
        return flavor?.nombre ? flavor.nombre : 'Nombre del perfil';
      }
      case 'hexcode': {
        return flavor?.hexcode ? flavor.hexcode : '#000';
      }
      case 'group': {
        const selectedFlavors = [
          flavor?.categoriaA,
          flavor?.categoriaB,
          flavor?.categoriaC,
          flavor?.categoriaD,
        ]
          .filter(Boolean)
          .join(', ');

        return selectedFlavors ? selectedFlavors : '-';
      }
      case 'type': {
        return flavor?.tipo ? flavor.tipo : '-';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Card maxHeight="85vh" overflowY="auto">
      <Flex justify="space-between" px="10px" pt="5px" mb="25px" align="center">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Selección de perfil
        </Text>
      </Flex>
      <Tabs>
        <TabPanels mb="20px">
          <TabPanel p="0px">
            <Perfil
              name={handlePerfilRendering('name')}
              hexcode={handlePerfilRendering('hexcode')}
              group={handlePerfilRendering('group')}
              type={handlePerfilRendering('type')}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex
        direction="column"
        bg={boxBg}
        p="16px 20px"
        borderRadius="14px"
        mb="38px"
      >
        <Text fontSize="sm" fontWeight="700" color={textColor}>
          Instrucciones de uso
        </Text>
        <Text fontSize="sm" fontWeight="500" color="secondaryGray.600">
          Selecciona los aromas y sabores buscados para obtener los folios de
          café más acertados al perfil deseado.
        </Text>
      </Flex>
      <Text fontSize="xl" color={textColor} fontWeight="700" mb="34px">
        Aromas | Sabores identificados
      </Text>
      {selectedFlavors?.length > 0 && (
        <Flex justify="center" mb={'15px'}>
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            loadingText="Buscando..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Buscar perfiles
          </Button>
        </Flex>
      )}
      <Flex flexDirection="column" justify="flex-start">
        {selectedFlavors?.map((x) => {
          return (
            x.nombre &&
            x.hexcode && (
              <FlavorItem
                key={x.hexcode}
                mb="26px"
                name={x.nombre}
                specification={`${handlePerfilRendering(
                  'type',
                  x,
                )} | ${handlePerfilRendering('group', x)}`}
                selectedFlavors={selectedFlavors}
                setSelectedFlavors={setSelectedFlavors}
                icon={
                  <Icon
                    as={GiCoffeeBeans}
                    color={iconColor}
                    w="20px"
                    h="18px"
                  />
                }
              />
            )
          );
        })}
      </Flex>

      {selectedFlavors?.length === 0 && (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
          height="100%"
        >
          <Icon as={GiCoffeeBeans} color={iconColor} w="40px" h="40px" />
          <Text
            fontSize="sm"
            color={textColor}
            fontWeight="500"
            mb="34px"
            textAlign="center"
          >
            No hay valores registrados aún.
          </Text>
        </Flex>
      )}
    </Card>
  );
}
