import { Chip } from '@mui/material';

export const tableColumnsInventarioMUI = [
  {
    headerName: 'ID',
    field: 'folio',
    width: 200,
  },
  {
    headerName: 'Comunidad',
    field: 'comunidad',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Pergamino seco',
    field: 'pergamino',
    dataType: 'kilograms',
    width: 200,
  },
  { headerName: 'Cliente', field: 'cliente', dataType: 'cliente', width: 200 },
  {
    headerName: 'Estatus',
    field: 'estatus',
    width: 200,
    renderCell: (params) => (
      <Chip
        label={params.value.toUpperCase() || 'SIN ESTATUS'}
        color={'default'}
        variant="solid"
      />
    ),
  },
];
