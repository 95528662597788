import {
  Box,
  Divider,
  Flex,
  Icon,
  ModalBody,
  ModalHeader,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { changeStatus } from 'api/secado';
import IconBox from 'components/icons/IconBox';
import FolioHeader from 'components/modal/status/components/FolioHeader';
import { BiError } from 'react-icons/bi';
import {
  LiaCheckSolid,
  LiaClipboardCheckSolid,
  LiaHandshake,
  LiaTagsSolid,
} from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function SelectOption({
  message,
  setSelectedOption,
  setConfirm,
  setIsLoading,
  setStatusChanged,
}) {
  const TOKEN = useSelector((state) => state.auth.tokenId);

  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const buttonBg = useColorModeValue('gray.100', 'whiteAlpha.100');
  const blueIcon = useColorModeValue('blue.500', 'white');
  const yellowIcon = useColorModeValue('yellow.500', 'white');
  const greenIcon = useColorModeValue('green.500', 'white');
  const redIcon = useColorModeValue('red.500', 'white');
  const navigate = useNavigate();

  const handleMarkAsAvailable = async () => {
    if (message.selectedFolio.id) {
      setIsLoading(true);
      try {
        const response = await changeStatus(
          TOKEN,
          message.selectedFolio.id,
          'disponible',
        );
        const isSuccess = response.status === 200;
        setStatusChanged(true);
        setConfirm({
          title: isSuccess ? 'Operación exitosa' : 'Error en operación',
          subtitle: isSuccess
            ? `El folio de café número ${message.selectedFolio.id} ha sido marcado nuevamente como disponible.`
            : 'Favor de intentar de nuevo o contactar a un agente de soporte.',
          icon: (
            <IconBox
              className="icon-box-maquila"
              h="42px"
              w="42px"
              bg={boxBg}
              icon={
                <Icon
                  as={isSuccess ? LiaCheckSolid : BiError}
                  color={isSuccess ? greenIcon : redIcon}
                  w="20px"
                  h="18px"
                />
              }
            />
          ),
        });
      } catch (error) {
        console.error(error);
        setConfirm({
          title: 'Error en operación',
          subtitle:
            'Favor de intentar de nuevo o contactar a un agente de soporte.',
          icon: (
            <IconBox
              className="icon-box-maquila"
              h="42px"
              w="42px"
              bg={boxBg}
              icon={<Icon as={BiError} color={redIcon} w="20px" h="18px" />}
            />
          ),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOfertaClick = () => {
    setSelectedOption('oferta');
  };

  const handleMaquilaClick = () => {
    setConfirm((prevState) => ({
      ...prevState,
      title: 'Ordenar a maquila',
      subtitle: `¿Estás seguro de seleccionar el folio de café número ${message.selectedFolio.id} para trilla?`,
      icon: (
        <IconBox
          h="42px"
          w="42px"
          bg={boxBg}
          icon={<Icon as={LiaTagsSolid} color={yellowIcon} w="20px" h="18px" />}
        />
      ),
      primaryBtnText: 'Maquilar folio',
      primaryBtnAction: () => {
        message.selectedFolio.folioSecado = message.selectedFolio.id;
        navigate(`/admin/seco/maquila/orden-folio`, {
          state: { selectedFolio: message.selectedFolio },
        });
      },
      secondaryBtnText: 'Volver',
      secondaryBtnAction: () => setSelectedOption(''),
    }));
    setSelectedOption('confirm');
  };

  const handleDisponibleClick = () => {
    setConfirm((prevState) => ({
      ...prevState,
      title: 'Marcar disponible',
      subtitle: `¿Estás seguro de marcar el folio de café número ${message.selectedFolio.id} como disponible de nuevo?`,
      icon: (
        <IconBox
          h="42px"
          w="42px"
          bg={boxBg}
          icon={
            <Icon
              as={LiaClipboardCheckSolid}
              color={greenIcon}
              w="20px"
              h="18px"
            />
          }
        />
      ),
      primaryBtnText: 'Marcar disponible',
      primaryBtnAction: () => handleMarkAsAvailable(),
      secondaryBtnText: 'Volver',
      secondaryBtnAction: () => setSelectedOption(''),
    }));
    setSelectedOption('confirm');
  };

  const handleTitle = (status) => {
    switch (status) {
      case 'disponible':
        return 'disponible';
      case 'oferta':
        return 'apartado para muestra-oferta';
      case 'maquila':
        return 'ordenado a maquila';
      default:
        return 'de café';
    }
  };

  return (
    <ModalBody>
      <FolioHeader message={message} />
      <Divider w="80%" alignSelf="center" margin="auto" />
      <ModalHeader
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        mt="20px"
        pb="0px"
        textAlign="center"
        fontSize="1.6rem"
      >
        Folio {handleTitle(message.selectedFolio.estatus)}
      </ModalHeader>
      <Flex mb={10} justifyContent="center" alignItems="center" w="100%">
        <Text
          maxW="220px"
          textAlign="center"
          color="secondaryGray.600"
          fontSize="sm"
          fontWeight="500"
        >
          Seleccione la opción deseada para cambio de estatus
        </Text>
      </Flex>
      <Flex justifyContent="space-evenly" alignItems="center" w="100%">
        {message.selectedFolio.estatus.trim().toLowerCase() ===
          'disponible' && (
          <>
            <OptionBox
              onClick={handleOfertaClick}
              boxBg={boxBg}
              buttonBg={buttonBg}
              icon={
                <Icon as={LiaHandshake} color={blueIcon} w="20px" h="18px" />
              }
              title="Muestra Oferta"
              description="Asigna un cliente y aparta el folio de café para muestra"
            />
            <OptionBox
              onClick={handleMaquilaClick}
              boxBg={boxBg}
              buttonBg={buttonBg}
              icon={
                <Icon as={LiaTagsSolid} color={yellowIcon} w="20px" h="18px" />
              }
              title="Ordenar a maquila"
              description="Seleccionar folio de café para trilla"
            />
          </>
        )}
        {message.selectedFolio.estatus.trim().toLowerCase() === 'oferta' && (
          <>
            <OptionBox
              onClick={handleOfertaClick}
              boxBg={boxBg}
              buttonBg={buttonBg}
              icon={
                <Icon as={LiaHandshake} color={blueIcon} w="20px" h="18px" />
              }
              title="Cliente nuevo"
              description="Asigna este folio de café para otro cliente"
            />
            <OptionBox
              onClick={handleDisponibleClick}
              boxBg={boxBg}
              buttonBg={buttonBg}
              icon={
                <Icon
                  as={LiaClipboardCheckSolid}
                  color={greenIcon}
                  w="20px"
                  h="18px"
                />
              }
              title="Disponible"
              description="Marca este folio de café de nuevo como disponible"
            />
            <OptionBox
              onClick={handleMaquilaClick}
              boxBg={boxBg}
              buttonBg={buttonBg}
              icon={
                <Icon as={LiaTagsSolid} color={yellowIcon} w="20px" h="18px" />
              }
              title="Ordenar a maquila"
              description="Seleccionar folio de café para trilla"
            />
          </>
        )}
      </Flex>
    </ModalBody>
  );
}

const OptionBox = ({ onClick, boxBg, buttonBg, icon, title, description }) => (
  <Box _hover={{ '.icon-box': { bg: 'white' } }} onClick={onClick}>
    <Flex
      direction="column"
      align="center"
      gap="10px"
      maxWidth="200px"
      textAlign="center"
      bg={buttonBg}
      _hover={{
        bg: boxBg,
        cursor: 'pointer',
        transform: 'scale(1.03)',
        transition: 'all 0.6s',
      }}
      padding="15px"
      borderRadius="15px"
    >
      <IconBox className="icon-box" h="42px" w="42px" bg={boxBg} icon={icon} />
      <Text fontSize="md" me="6px" fontWeight="700">
        {title}
      </Text>
      <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
        {description}
      </Text>
    </Flex>
  </Box>
);
