import { Button, Flex, useColorModeValue } from '@chakra-ui/react';

function Buttons(props) {
  const bgButton = useColorModeValue('whiteAlpha.300', 'whiteAlpha.300');

  const { tableMode, setTableMode } = props;

  return (
    <Flex gap="10px">
      <Button
        backgroundColor={tableMode === 'Entradas' ? bgButton : 'inherit'}
        color={'white'}
        onClick={() => setTableMode('Entradas')}
      >
        Entradas
      </Button>
      <Button
        backgroundColor={tableMode === 'Salidas' ? bgButton : 'inherit'}
        color={'white'}
        onClick={() => setTableMode('Salidas')}
      >
        Salidas
      </Button>
      <Button
        backgroundColor={tableMode === 'Inventario' ? bgButton : 'inherit'}
        color={'white'}
        onClick={() => setTableMode('Inventario')}
      >
        Inventario
      </Button>
    </Flex>
  );
}

export default Buttons;
