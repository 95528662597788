import {
  Button,
  Flex,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

import { IoRemoveCircleOutline } from 'react-icons/io5';

export default function Header({ id, deleteEntry }) {
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  return (
    <Flex
      align="center"
      justify="space-between"
      w="100%"
      mb={'1rem'}
      px={'25px'}
    >
      <Flex alignItems="center" flexDirection="row" me="14px">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {'Registro ' + id}
        </Text>
        <Text
          ms="auto"
          color={textColor}
          fontSize="sm"
          me="6px"
          fontWeight="700"
        ></Text>
      </Flex>
      <Tooltip hasArrow label="Eliminar registro" bg="gray.300" color="black">
        <Button onClick={() => deleteEntry(id)}>
          <IoRemoveCircleOutline fontSize="1.5rem" />
        </Button>
      </Tooltip>
    </Flex>
  );
}
