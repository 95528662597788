// Chakra imports
import {
  Checkbox,
  Flex,
  FormLabel,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { MdOutlineMonitorWeight } from 'react-icons/md';
import { formatTableValue } from 'utils/format';

export default function SalidaPrintable({ weightExits, previewMode }) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const vehicleForm = (weightExit) => {
    const weightExitValues = [
      {
        title: 'Operador / Chofer',
        value: weightExit?.chofer || 'No especificado',
      },
      { title: 'Placas', value: weightExit?.placas || 'No especificado' },
      { title: 'Marca', value: weightExit?.marca || 'No especificado' },
      {
        title: 'Número de unidad',
        value: weightExit?.unidad || 'No especificado',
      },
    ];

    return (
      <>
        <SimpleGrid
          columns={{ base: '4', md: '4', lg: '4' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
          px={'2rem'}
        >
          {weightExitValues.map((x, index) => (
            <Flex key={index} direction="column" mb="1rem">
              <Flex>
                <FormLabel
                  display="flex"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  {x.title}
                </FormLabel>
              </Flex>
              <Text>{x.value}</Text>
            </Flex>
          ))}
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '3', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
          px={'2rem'}
        >
          <Flex direction="column" mb="1rem">
            <Text fontSize="md" fontWeight="500" color={textColor}>
              Camión en condiciones de sanidad exterior e interior, en piso
              cubierta y techo
            </Text>
          </Flex>
          <Flex direction="column" mb="1rem">
            <Stack spacing={5} direction="row">
              <Checkbox
                isChecked={weightExit?.cumple === 'Cumple'}
                color={textColor}
              >
                Cumple
              </Checkbox>
              <Checkbox
                isChecked={weightExit?.cumple === 'No cumple'}
                color={textColor}
              >
                No cumple
              </Checkbox>
            </Stack>
          </Flex>
        </SimpleGrid>
      </>
    );
  };

  const pesoForm = (weightExit) => {
    const weightExitValues = [
      {
        title: 'Folio de maquila',
        value: weightExit?.folioMaquila || 'No especificado',
        type: '',
      },
      {
        title: 'Número ICO',
        value: weightExit?.numeroIco || 'No especificado',
        type: '',
      },
      {
        title: 'Característica de café',
        value: weightExit?.caracteristica || 'No especificado',
        type: '',
      },
      {
        title: 'Sacos',
        value: weightExit?.sacosTotal || 'No especificado',
        type: '',
      },
      {
        title: 'Peso bruto total',
        value: weightExit?.kilosBrutoTotal || 'No especificado',
        type: weightExit?.kilosBrutoTotal ? 'kilograms' : '',
      },
      {
        title: 'Peso bruto total',
        value: weightExit?.kilosBrutoTotal || 'No especificado',
        type: weightExit?.kilosBrutoTotal ? 'kilograms' : '',
      },
      {
        title: 'Precio por libra',
        value: weightExit?.precioLibra || 'No especificado',
        type: weightExit?.precioLibra ? 'pesos' : '',
      },
      {
        title: 'Observaciones',
        value: weightExit?.observaciones || 'No especificado',
        type: '',
      },
    ];

    return (
      <SimpleGrid
        columns={{ base: '4', md: '4', lg: '4' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'2rem'}
      >
        {weightExitValues.map((x, index) => (
          <Flex key={index} direction="column" mb="1rem">
            <Flex>
              <FormLabel
                display="flex"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
              >
                {x.title}
              </FormLabel>
            </Flex>
            <Text>{formatTableValue(x.type, x.value)}</Text>
          </Flex>
        ))}
      </SimpleGrid>
    );
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        borderWidth="2px"
        borderColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex gap="15px" flexDirection="row" me="14px">
            <MdOutlineMonitorWeight color="#1B254B" size={'25px'} />
            <Text
              color={balanceBg}
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Peso por unidad o transporte
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {weightExits?.map((x) => (
        <>
          {!previewMode && (
            <Flex
              align="center"
              justify="space-between"
              w="100%"
              mb={'1rem'}
              px={'25px'}
            >
              <Flex alignItems="center" flexDirection="row" me="14px">
                <Text
                  color={textColor}
                  fontSize="22px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {'Registro ' + x.id}
                </Text>
              </Flex>
            </Flex>
          )}
          {vehicleForm(x)}
          {pesoForm(x)}
        </>
      ))}
    </Card>
  );
}
