import {
  Flex,
  FormLabel,
  Input,
  InputGroup,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';

export default function Observaciones({
  id,
  weightEntry,
  handleFocus,
  handleWeightEntryChange,
}) {
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  return (
    <SimpleGrid
      columns={{ base: '1', md: '1', lg: '1' }}
      gap={{ sm: '10px', md: '26px' }}
      mb={'1rem'}
      px={'20px'}
    >
      <Flex direction="column">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Observaciones
        </FormLabel>
        <InputGroup>
          <Input
            value={weightEntry.observaciones}
            onChange={(e) =>
              handleWeightEntryChange('observaciones', e.target.value, id)
            }
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Observaciones"
            variant="auth"
            size="lg"
          />
        </InputGroup>
      </Flex>
    </SimpleGrid>
  );
}
