import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Chakra imports
import {
  CircularProgress,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import ResultMessage from 'components/modal/ResultMessage';
import ChangeStatus from 'components/modal/status/ChangeStatus';
import Balance from 'views/admin/inventarios/folios/components/Balance';

// API methods
import { getAllEntradas } from 'api/entradas';
import { getAllSalida } from 'api/salida';
import { getAllSecado } from 'api/secado';

// Others
import { VSeparator } from 'components/separator/Separator';
import DataTable from 'components/table/dataTable';
import { ALERT } from 'constant/constant';
import { useNavigate } from 'react-router-dom';
import Header from 'views/admin/inventarios/folios/components/table/Header';
import { tableColumnsEntradaMUI } from 'views/admin/inventarios/folios/variables/tableColumnsEntradaMUI';
import { tableColumnsInventarioMUI } from 'views/admin/inventarios/folios/variables/tableColumnsInventarioMUI';
import { tableColumnsSalidaMUI } from 'views/admin/inventarios/folios/variables/tableColumnsSalidaMUI';

export default function Default() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const navigate = useNavigate();
  const [tableMode, setTableMode] = useState('Inventario');
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [salida, setSalida] = useState([]);
  const [entrada, setEntrada] = useState([]);
  const [secado, setSecado] = useState([]);
  const [balance, setBalance] = useState([]);

  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });
  const [messageChangeStatus, setMessageChangeStatus] = useState({
    state: false,
    selectedFolio: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [entradaResponse, salidaResponse, secadoResponse] =
          await Promise.all([
            getAllEntradas(TOKEN),
            getAllSalida(TOKEN),
            getAllSecado(TOKEN),
          ]);

        if (
          entradaResponse.status === 200 &&
          salidaResponse.status === 200 &&
          secadoResponse.status === 200
        ) {
          const [entradaResult, salidaResult, secadoResult] = await Promise.all(
            [
              entradaResponse.json(),
              salidaResponse.json(),
              secadoResponse.json(),
            ],
          );

          const entradaData = entradaResult?.data?.data || [];
          const salidaData = salidaResult?.data?.data || [];
          const secadoData =
            secadoResult?.data?.data.filter(
              (x) => x.folio && x.estatus !== 'salida',
            ) || [];

          setEntrada(entradaData);
          setSalida(salidaData);
          setSecado(secadoData);

          const calculateBalance = (secadoData) => {
            let totalBalance = [
              {
                pergamino: { total: 0, disponible: 0, oferta: 0, maquila: 0 },
                pergaminera: { total: 0, disponible: 0, oferta: 0, maquila: 0 },
                merma: { total: 0, disponible: 0, oferta: 0, maquila: 0 },
              },
            ];

            for (let secado of secadoData) {
              switch (secado.estatus) {
                case 'disponible':
                  totalBalance[0].pergamino.disponible += +secado.pergamino;
                  totalBalance[0].pergaminera.disponible += +secado.pergaminera;
                  totalBalance[0].merma.disponible += +secado.merma;
                  break;
                case 'oferta':
                  totalBalance[0].pergamino.oferta += +secado.pergamino;
                  totalBalance[0].pergaminera.oferta += +secado.pergaminera;
                  totalBalance[0].merma.oferta += +secado.merma;
                  break;
                case 'maquila':
                  totalBalance[0].pergamino.maquila += +secado.pergamino;
                  totalBalance[0].pergaminera.maquila += +secado.pergaminera;
                  totalBalance[0].merma.maquila += +secado.merma;
                  break;
                default:
                  totalBalance[0].pergamino.disponible += +secado.pergamino;
                  totalBalance[0].pergaminera.disponible += +secado.pergaminera;
                  totalBalance[0].merma.disponible += +secado.merma;
                  break;
              }

              totalBalance[0].pergamino.total += +secado.pergamino;
              totalBalance[0].pergaminera.total += +secado.pergaminera;
              totalBalance[0].merma.total += +secado.merma;
            }

            return totalBalance;
          };

          const totalBalance = calculateBalance(secadoData);
          setBalance(totalBalance);
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [refetch]);

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleCloseChangeStatusModal = () => {
    setMessageChangeStatus({
      state: false,
      selectedFolio: '',
    });
  };

  const handleChangeStatus = (params) => {
    if (params?.row) {
      setMessageChangeStatus({
        state: 'success',
        selectedFolio: params.row,
      });
    } else {
      setMessageChangeStatus({
        state: 'error',
        selectedFolio: '',
      });
    }
  };

  const handleSalidaDetailNavigate = (params) => {
    if (params?.row) {
      navigate(`/admin/seco/salidas/registro`, {
        state: { selectedSalida: params.row, previewMode: true },
      });
    } else {
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
    }
  };

  const handleEntradaDetailNavigate = (params) => {
    if (params?.row) {
      const selectedEntradaById = entrada.find((x) => x.id === params?.row?.id);

      navigate(`/admin/seco/entradas/registro`, {
        state: {
          selectedEntrada: selectedEntradaById || params.row,
          previewMode: true,
        },
      });
    } else {
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      {!isLoading && (
        <Flex direction="column" width="stretch">
          <Grid
            mb="20px"
            gridTemplateColumns={{
              base: 'repeat(2, 1fr)',
            }}
            gap="20px"
            display={{ base: 'block', lg: 'grid' }}
          >
            <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
              {balance.length > 0 && <Balance balance={balance} />}
            </Flex>
          </Grid>
          <Flex flexDirection="column" gap="2rem">
            <Header tableMode={tableMode} setTableMode={setTableMode} />
            {tableMode === 'Inventario' && (
              <DataTable
                rows={secado}
                columns={tableColumnsInventarioMUI}
                addActions="change status"
                iconFunction={handleChangeStatus}
                iconLabel="Cambiar estatus"
              />
            )}
            {tableMode === 'Entradas' && (
              <DataTable
                rows={entrada}
                columns={tableColumnsEntradaMUI}
                addActions="see detail"
                iconFunction={handleEntradaDetailNavigate}
                iconLabel="Ver Detalle"
              />
            )}
            {tableMode === 'Salidas' && (
              <DataTable
                rows={salida}
                columns={tableColumnsSalidaMUI}
                addActions="see detail"
                iconFunction={handleSalidaDetailNavigate}
                iconLabel="Ver Detalle"
              />
            )}
          </Flex>
        </Flex>
      )}
      {isLoading && (
        <Flex
          width={'100%'}
          height={'80vh'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          gap={'10px'}
        >
          <Text>Cargando...</Text>
          <CircularProgress isIndeterminate color={balanceBg} size={100} />
        </Flex>
      )}
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
      <ChangeStatus
        isOpen={messageChangeStatus.state}
        onClose={handleCloseChangeStatusModal}
        message={messageChangeStatus}
        setRefetch={setRefetch}
      />
    </Flex>
  );
}
