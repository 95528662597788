export const ENTRADA = {
  HUMEDAD_TOOLTIP:
    'El porcentaje excedente a partir del 11.00% es multiplicado por el peso neto y el precio por kilogramo para obtener el descuento por humedad. ',
};

export const CATACION = {
  SUCCESS: 'El registro de perfil fue guardado correctamente.',
  FOLIO_TOOLTIP: 'Listado excluye folios con orden de maquila o salida.',
  COMUNIDAD_TOOLTIP:
    'Si la comunidad ya cuenta con un perfil asignado, este será actualizado con el nuevo perfil seleccionado.',
};

export const MAQUILA = {
  FOLIO_TOOLTIP: 'Listado excluye folios con orden de salida.',
};

export const RENDIMIENTO = {
  FOLIO_TOOLTIP: 'Listado excluye folios con orden de maquila o salida.',
};

export const SALIDA = {
  FOLIO_TOOLTIP:
    'Listado excluye folios de maquila con orden previa de salida.',
};

export const ALERT = {
  SUCCESS_TITLE: 'Operación exitosa.',
  SUCCESS_SUBTITLE: 'La información brindada fue registrada correctamente.',
  ERROR_TITLE: 'Error en la operación.',
  ERROR_SUBTITLE:
    'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
  ERROR_FIELDS: 'Favor de llenar todos los campos requeridos.',
};

export const OFERTA = {
  WARNING_ESTATUS: 'warning',
  WARNING_TITLE: 'Advertencia: folio apartado.',
  WARNING_SUBTITLE:
    'Este folio de café ha sido asignado para muestra oferta a cliente. Favor de contactar a un agente administrativo antes de proceder a maquilar.',
};
