// Chakra imports
import {
  Flex,
  FormLabel,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import { getCurrentDate } from 'utils/format';
import galgueraIcon from '../../assets/icon/logo-galguera-blanco.png';

export default function HeaderPrintable({ title }) {
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const brandColor = useColorModeValue('brand.900', 'white');
  const logoFilter = useColorModeValue('invert(100%)', 'unset');
  return (
    <Card direction="column" w="100%" className="printable-header">
      <Flex
        alignItems="center"
        justify="space-around"
        mt="10px"
        bgPosition="right"
        bgSize="cover"
      >
        <Flex direction="column" flexBasis="33.33%">
          <Image
            filter={logoFilter}
            display={{ sm: 'block' }}
            me={'1rem'}
            width={'100px'}
            src={galgueraIcon}
            mb={'1rem'}
          />
          <Text fontSize="13px">jesusgalguera108@hotmail.com</Text>
          <Text fontSize="13px">TEL. 95 1518-7611, 95 1518-7700</Text>
        </Flex>
        <Flex direction="column" flexBasis="33.33%" alignItems="center">
          <Text
            textAlign="center"
            color={brandColor}
            fontSize="30px"
            fontWeight="700"
            lineHeight="100%"
          >
            {title ? title.toUpperCase() : 'REPORTE'}
          </Text>
        </Flex>
        <Flex direction="column" flexBasis="33.33%" alignItems="flex-end">
          <Flex>
            <FormLabel
              display="flex"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              Fecha: {getCurrentDate()}
            </FormLabel>
          </Flex>
          <Text fontSize="13px">Carretera Oaxaca - México KM 7.5</Text>
          <Text fontSize="13px">San Pablo Etla, Oaxaca, C.P. 68258</Text>
        </Flex>
      </Flex>
    </Card>
  );
}
