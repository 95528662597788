export const tableColumnsSalidaMUI = [
  {
    headerName: 'ID',
    field: 'registerId',
    width: 100,
  },
  { headerName: 'Salida', field: 'salidaId', width: 100 },
  {
    headerName: 'Número ICO',
    field: 'numeroIco',
    width: 200,
  },
  {
    headerName: 'Cliente',
    field: 'cliente',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Peso neto total',
    field: 'kilosNetoTotal',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Precio por libra',
    field: 'precioLibra',
    dataType: 'pesos',
    width: 200,
  },
];
