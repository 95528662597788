// Chakra imports
import { Divider, Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

export default function FooterPrintable() {
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  return (
    <Card direction="column" w="100%">
      <Flex
        alignItems="center"
        justify="space-around"
        my="10px"
        bgPosition="right"
        bgSize="cover"
      >
        <Flex
          direction="column"
          alignItems="center"
          mb="1rem"
          gap="20px"
          px="2rem"
          flexBasis="33.33%"
        >
          <Text>ENTREGA</Text>
          <Divider mt="1rem" />
          <Text display="flex" fontSize="sm" fontWeight="500" color={textColor}>
            FIRMA
          </Text>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          mb="1rem"
          gap="20px"
          px="2rem"
          flexBasis="33.33%"
        >
          <Text>RECIBE</Text>
          <Divider mt="1rem" />
          <Text display="flex" fontSize="sm" fontWeight="500" color={textColor}>
            FIRMA
          </Text>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          mb="1rem"
          gap="20px"
          px="2rem"
          flexBasis="33.33%"
        >
          <Text>AUTORIZO</Text>
          <Divider mt="1rem" />
          <Text display="flex" fontSize="sm" fontWeight="500" color={textColor}>
            FIRMA
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
