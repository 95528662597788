import { Box, Checkbox } from '@chakra-ui/react';
import { ArcElement, Chart, Legend, PieController, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useRef, useState } from 'react';
import classes from '../css/FlavorWheel.module.css';

Chart.register(PieController, ArcElement, Tooltip, Legend, ChartDataLabels);

const FlavorWheel = ({ handleRenderFlavorItem }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [isTestMode, setIsTestMode] = useState(false);

  const dataTest = {
    datasets: [
      {
        labels: [
          'Floral',
          'Fragrante',
          'Cítrico',
          'Baya',
          'Cebolla',
          'Leguminoso',
          'Nuez',
          'Malta',
          'Dulce',
          'Jarabe',
          'Achocolatado',
          'Vainilla',
          'Trementina',
          'Medicinal',
          'Cálido',
          'Agudo',
          'Ahumado',
          'Ceniza',
          'Creosota',
          'Fenólico',
          'Cáustico',
          'Alkalino',
          'Astringente',
          'Áspero',
          'Neutro',
          'Seco',
          'Delicado',
          'Dulce',
          'Punzante',
          'Picante',
          'Acrimonioso',
          'Agridulce',
          'Duro',
          'Acre',
        ],
        backgroundColor: [
          '#008B8B',
          '#3CB371',
          '#607979',
          '#20B2AA',
          '#6380B8',
          '#2E8B57',
          '#4169E1',
          '#496989',
          '#194351',
          '#268566',
          '#3F5D77',
          '#87CEFA',
          '#116F6F',
          '#A8D9EC',
          '#485F5F',
          '#5F9EA0',
          '#71A6AD',
          '#25613F',
          '#4682B4',
          '#008080',
          '#87CEEB',
          '#51639A',
          '#5696D6',
          '#6F9DAC',
          '#49CDA1',
          '#20B2AA',
          '#9AC8E5',
          '#6B8E23',
          '#6495ED',
          '#2C5656',
          '#48A471',
          '#387C7D',
          '#3B6753',
          '#115AA3',
        ],
        data: [
          2.75, 2.75, 2.75, 2.75, 2.75, 2.75, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8,
          2.8, 2.8, 2.8, 2.75, 2.75, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125,
          3.125, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125,
        ],
        hoverOffset: 14,
      },
      {
        labels: [
          'Acre',
          'Aspero',
          'Agudo',
          'Simple',
          'Terso',
          'Ácido',
          'Vinoso',
          'Agrio',
          'Floral',
          'Afrutado',
          'Herbal',
          'Nuez',
          'Acaramelado',
          'Achocolatado',
          'Resinoso',
          'Especies',
          'Carbón',
        ],
        backgroundColor: [
          '#A74C5A', // Rusty Pink
          '#A65279', // Berry Pink
          '#8C4676', // Wine Purple
          '#6D4269', // Plum Purple
          '#5A395B', // Aubergine
          '#4D3E4A', // Muted Plum
          '#A55470', // Rosy Pink
          '#944469', // Dusty Rose
          '#84546A', // Vintage Plum
          '#6E465A', // Deep Berry
          '#664E5A', // Mulberry
          '#583D55', // Dark Plum
          '#73455D', // Cranberry
          '#7C426C', // Bordeaux
          '#834C6D', // Amaranth Purple
          '#7B4164', // Magenta Purple
          '#8A5066', // Maroon
        ],
        data: [
          5.5, 5.5, 5.5, 5.6, 5.6, 5.6, 5.6, 5.6, 5.5, 6.25, 6.25, 6.25, 6.25,
          6.25, 6.25, 6.25, 6.25,
        ],
        hoverOffset: 14,
      },
      {
        labels: [
          'Enzimático',
          'Caramelización',
          'Destilación seca',
          'Amargo',
          'Salado',
          'Dulce',
          'Ácido',
        ],
        backgroundColor: [
          '#A52A2A',
          '#752323',
          '#8B4513',
          '#603000',
          '#8B4513',
          '#72481f',
          '#A08020',
        ],
        data: [16.5, 16.8, 16.7, 12.5, 12.5, 12.5, 12.5],
        hoverOffset: 14,
      },
      {
        labels: ['Aromas', 'Sabores'],
        backgroundColor: ['#4682B4', '#2D577E'],
        data: [50, 50],
        hoverOffset: 0,
      },
    ],
  };

  const data = {
    datasets: [
      {
        labels: [
          // ** --- Dulce --- ** //
          // 'Azúcar morena'
          'Melaza',
          'Jarabe de acre',
          'Caramelizado',
          'Miel',
          // 'Vainilla',
          '',
          // 'Aroma de vainilla',
          '',
          // 'Dulce general',
          '',
          // 'Arómaticos dulces',
          '',
          // ** --- Floral --- ** //
          // 'Té negro'
          '',
          //'Floral',
          'Manzanilla',
          'Rosa',
          // ** --- Afrutado --- ** //
          // 'Bayas',
          'Mora',
          'Frambuesa',
          'Arándano',
          'Fresa',
          // 'Frutos secos',
          'Pasas de uva',
          'Pasas de ciruela',
          // 'Otras frutas',
          'Coco',
          'Cereza',
          'Granada',
          'Piña ananas',
          'Uva',
          'Manzana',
          'Durazno',
          'Pera',
          // 'Cítricos',
          'Pomelo',
          'Naranja',
          'Limón',
          'Lima',

          // ** --- Ácido fermentado --- ** //
          // 'Ácido'
          'Aromáticos ácidos',
          'Ácido acético',
          'Ácido butírico',
          'Ácido isovalérico',
          'Ácido cítrico',
          'Ácido málico',
          // 'Alcohol fermentado'
          'Avinado',
          'Whisky',
          'Fermentado',
          'Sobre maduro',

          // ** --- Verde vegetal --- ** //
          // 'Aceite de oliva'
          '',
          // 'Crudo'
          '',
          // 'Verde vegetal'
          'Inmaduro',
          'Vaina',
          'Fresco',
          'Verde oscuro',
          'Vegetal',
          'Heno',
          'Herbáceo',
          // 'Leguminoso'
          '',
          // ** --- Otros --- ** //
          //'Papel humedad',
          'Rancio',
          'Cartón',
          'Madera',
          'Mohoso',
          'Polvo',
          'Tierra',
          'Animal',
          'Carne caldo',
          'Fenólico',
          //'Químico',
          'Amargo',
          'Medicinal',
          'Petróleo',
          'Zorrillo',
          'Goma',

          // ** --- Tostado --- ** //
          // 'Tabaco'
          '',
          // 'Tabaco de pipa'
          '',
          // 'Quemado'
          'Acre',
          'Ceniza',
          'Humo',
          'Marrón tostado',
          // 'Cereal'
          'Grano',
          'Malta',

          // ** --- Especias --- ** //
          // 'Acre'
          '',
          // 'Pimienta'
          '',
          // 'Especias marrones'
          'Anís',
          'Nuez moscada',
          'Canela',
          'Clavo',

          // ** --- Nueces cacao --- ** //
          // 'Nueces'
          'Maní',
          'Avellana',
          'Almendra',
          // 'Cacao'
          'Chocolate',
          'Chocolate amargo',
        ],
        backgroundColor: [
          '#220005',
          '#d65e26',
          '#e09d29',
          '#f47c28',
          '#fff',
          '#fff',
          '#fff',
          '#fff',
          '#fff',
          '#faaf26',
          '#e473a5',
          '#0a0919',
          '#dd2d86',
          '#6368ad',
          '#ef2938',
          '#9c2075',
          '#84558d',
          '#e28d28',
          '#e31559',
          '#ef495e',
          '#f59a29',
          '#9ec03d',
          '#73ba71',
          '#f17f51',
          '#b1a620',
          '#e95d5b',
          '#f47820',
          '#f3d600',
          '#8fc053',
          '#c1ba1d',
          '#9dc48b',
          '#d6c408',
          '#72c058',
          '#e4d40d',
          '#b2c324',
          '#a40670',
          '#b03b55',
          '#d3a605',
          '#7f6d29',
          '#fff',
          '#fff',
          '#a8c645',
          '#48b34b',
          '#04a96b',
          '#00623d',
          '#04b36b',
          '#9e9e22',
          '#7bc057',
          '#fff',
          '#657d6d',
          '#d9bf44',
          '#715c27',
          '#a1ab76',
          '#caa668',
          '#968446',
          '#9da075',
          '#ca7f79',
          '#e67c88',
          '#6ec8be',
          '#62a8c3',
          '#02a8c2',
          '#5d8295',
          '#01162f',
          '#fff',
          '#fff',
          '#af9f69',
          '#94a894',
          '#ab7c2e',
          '#815621',
          '#cfa388',
          '#e89960',
          '#fff',
          '#fff',
          '#c59b22',
          '#a81618',
          '#e6922f',
          '#b07a6b',
          '#e3b50a',
          '#955d23',
          '#d7a89a',
          '#68281f',
          '#47251d',
        ],
        data: [
          // ** --- Dulce --- ** //  4, 1, 1, 1, 1,
          // 'Azúcar morena'
          1, 1, 1, 1,
          // 'Vainilla',
          1,
          // 'Aroma de vainilla',
          1,
          // 'Dulce general',
          1,
          // 'Arómaticos dulces',
          1,

          // ** --- Floral --- ** // 2, 2,
          // 'Té negro'
          2,
          //'Floral',
          1, 1,

          // ** --- Afrutado --- ** // 4, 4, 8, 4,
          // 'Bayas',
          1, 1, 1, 1,
          // 'Frutos secos',
          2, 2,
          // 'Otras frutas',
          1, 1, 1, 1, 1, 1, 1, 1,
          // 'Cítricos',
          1, 1, 1, 1,

          // ** --- Ácido/Fermentado --- ** //  6, 6,
          // 'Ácido'
          1, 1, 1, 1, 1, 1,
          // 'Alcohol/Fermentado'
          1, 1, 2, 2,

          // ** --- Verde/Vegetal --- ** // 2, 2, 7, 2,
          // 'Aceite de oliva'
          2,
          // 'Crudo'
          2,
          // 'Verde/Vegetal'
          1, 1, 1, 1, 1, 1, 1,
          // 'Leguminoso'
          2,

          // ** --- Otros --- ** // 12, 5,
          //'Papel/humedad',
          1, 1, 1, 1, 1, 1, 2, 2, 2,
          //'Químico',
          1, 1, 1, 1, 1,

          // ** --- Tostado --- ** // 1, 1, 6, 4,
          // 'Tabaco'
          1,
          // 'Tabaco de pipa'
          1,
          // 'Quemado'
          1, 1, 2, 2,
          // 'Cereal'
          2, 2,

          // ** --- Especias --- ** // 1, 1, 6,
          // 'Pimienta'
          1,
          // 'Acre'
          1,
          // 'Especias marrones'
          1, 1, 2, 2,

          // ** --- Nueces/cacao --- ** // 3, 3,
          // 'Nueces'
          1, 1, 1,
          // 'Cacao'
          1.5, 1.5,
        ],
        hoverOffset: 14,
      },
      {
        labels: [
          // Dulce
          'Azúcar morena',
          'Vainilla',
          'Aroma de vainilla',
          'Dulce general',
          'Arómaticos dulces',

          // Floral
          'Té negro',
          'Floral',

          // Afrutado
          'Bayas',
          'Frutos secos',
          'Otras frutas',
          'Cítricos',

          // Ácido fermentado
          'Ácido',
          'Alcohol fermentado',

          // Verde vegetal
          'Aceite de oliva',
          'Crudo',
          'Verde vegetal',
          'Leguminoso',

          // Otros
          'Papel humedad',
          'Químico',

          // Tostado
          'Tabaco de pipa',
          'Tabaco',
          'Quemado',
          'Cereal',

          // Especias
          'Acre',
          'Pimienta',
          'Especias marrones',

          // Nueces cacao
          'Nueces',
          'Cacao',
        ],
        backgroundColor: [
          '#ce7c92',
          '#f4997e',
          '#f47c86',
          '#d76e7b',
          '#d13a6b',
          '#ac647c',
          '#ef5794',
          '#f02d4b',
          '#d5434e',
          '#f16449',
          '#fdb914',
          '#e0d627',
          '#af9f16',
          '#a0b218',
          '#698936',
          '#09b151',
          '#709d93',
          '#9ab9cb',
          '#61c4db',
          '#a0965f',
          '#cdb27e',
          '#b77f4e',
          '#e4bb2d',
          '#734864',
          '#e51233',
          '#bb404b',
          '#b69188',
          '#b37125',
        ],
        data: [
          // Dulce
          4, 1, 1, 1, 1,
          // Floral
          2, 2,
          // Afrutado
          4, 4, 8, 4,
          // Ácido/Fermentado
          6, 6,
          // Verde/Vegetal
          2, 2, 7, 2,
          // Otros
          12, 5,
          // Tostado
          1, 1, 6, 4,
          // Especias
          1, 1, 6,
          // Nueces/cacao
          3, 3,
        ],
        hoverOffset: 14,
      },
      {
        labels: [
          'Dulce', // 8
          'Floral', // 4
          'Afrutado', // 20
          'Ácido fermentado', // 12
          'Verde vegetal', // 13
          'Otros', // 17
          'Tostado', // 12
          'Especias', // 8
          'Nueces cacao', // 6
        ],
        backgroundColor: [
          '#f36420',
          '#ec008b',
          '#ed1b24',
          '#f2cc05',
          '#017e3a',
          '#01a3d1',
          '#d33728',
          '#b81041',
          '#997979',
        ],
        data: [8, 4, 20, 12, 13, 17, 12, 8, 6],
        hoverOffset: 0,
      },
      {
        labels: [''],
        backgroundColor: ['#fff'],
        data: [100],
        hoverOffset: 0,
      },
    ],
  };

  const config = {
    type: 'pie',
    data: isTestMode ? dataTest : data,
    options: {
      borderWidth: 1,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const labelIndex = context.dataIndex;
              return ' ' + context.dataset.labels[labelIndex];
            },
          },
        },
        datalabels: {
          color: '#FFF',
          labels: {
            title: {
              font: {
                weight: 'bold',
                size: '10',
              },
            },
          },

          formatter: (value, context) => {
            const labelIndex = context.dataIndex;
            const label = context.dataset.labels[labelIndex];
            return label && typeof label === 'string'
              ? label.toUpperCase()
              : '';
          },
          rotation: (context) => {
            const startAngle =
              context.chart._metasets[context.datasetIndex].data[
                context.dataIndex
              ].$context.element.startAngle;
            const endAngle =
              context.chart._metasets[context.datasetIndex].data[
                context.dataIndex
              ].$context.element.endAngle;
            const angle = (startAngle + endAngle) / 2;
            const midAngle = (angle * 180) / Math.PI;

            if (midAngle > 90 && midAngle < 270) {
              return midAngle + 180;
            } else {
              return midAngle;
            }
          },
        },
      },
      onClick: function (event, elements) {
        if (elements.length) {
          const segmentIndex = elements[0].index;
          const datasetIndex = elements[0].datasetIndex;
          const backgroundColor =
            data.datasets[datasetIndex].backgroundColor[segmentIndex];
          handleRenderFlavorItem(backgroundColor);
        }
      },
    },
  };

  const renderChart = () => {
    const ctx = chartRef.current.getContext('2d');
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    chartInstanceRef.current = new Chart(ctx, config);
  };

  useEffect(() => {
    renderChart();

    const handleResize = () => {
      renderChart();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [isTestMode]);

  const handleCheckboxChange = () => {
    setIsTestMode((prev) => !prev);
  };

  return (
    <Box position="relative" className={classes.box_canvas}>
      <Checkbox
        position="absolute"
        top="10px"
        left="10px"
        onChange={handleCheckboxChange}
      >
        Rueda de catación para pruebas
      </Checkbox>
      <canvas className={classes.canvas} ref={chartRef} />
    </Box>
  );
};

export default FlavorWheel;
