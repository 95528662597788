import { Chip } from '@mui/material';

export const tableColumnsCatacion = [
  {
    headerName: 'Folio de secado',
    field: 'folioSecado',
    width: 150,
  },
  {
    headerName: 'Tipo',
    field: 'clasificacion',
    width: 150,
    renderCell: (params) => (
      <Chip
        label={params.value.toUpperCase()}
        color={'default'}
        variant="solid"
      />
    ),
  },
  {
    headerName: 'Entrada ID',
    field: 'entradaId',
    width: 100,
  },
  {
    headerName: 'Fecha',
    field: 'fecha',
    dataType: 'date',
    width: 200,
  },
  {
    headerName: 'Clave de descarga',
    field: 'claveDescarga',
    width: 200,
  },
  {
    headerName: 'Perfiles',
    field: 'perfiles',
    dataType: 'perfil',
    width: 200,
  },
  {
    headerName: 'Puntaje',
    field: 'puntaje',
    width: 200,
  },
  {
    headerName: 'Región',
    field: 'region',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Comunidad',
    field: 'comunidad',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Productor',
    field: 'productor',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Tipo',
    field: 'tipo',
    width: 200,
  },
  {
    headerName: 'Cliente',
    field: 'cliente',
    dataType: 'string',
    width: 200,
  },
];
