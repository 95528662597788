import {
  CircularProgress,
  Flex,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getAllPerfil } from 'api/cafe';
import { addCatacion } from 'api/catacion';
import { getAllOrigen } from 'api/origen';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { ALERT, CATACION } from 'constant/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MuestrasPanel from 'views/admin/seco/catacion/components/MuestrasPanel';
import FlavorWheel from './components/FlavorWheel';

export default function CatacionMuestras() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const catacionInitialState = {
    folioSecado: 0,
    claveDescarga: '',
    entradaId: 0,
    fecha: '',
    puntaje: '',
    perfiles: '',
    estado: '',
    region: '',
    municipio: '',
    comunidad: '',
    productor: '',
    tipo: '',
    cliente: '',
    caracteristica: '',
    clasificacion: 'muestra',
    createdAt: '',
    createdBy: '',
  };

  const [flavors, setFlavors] = useState([]);
  const [selectedFlavors, setSelectedFlavors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [catacion, setCatacion] = useState(catacionInitialState);
  const [origen, setOrigen] = useState([]);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const fetchAllPerfil = async () => {
      try {
        setIsLoading(true);
        const response = await getAllPerfil(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData = result?.data?.data || [];
          setFlavors(resultData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          handleErrorMessage();
        }
      } catch (error) {
        setIsLoading(false);
        handleErrorMessage();
        console.error(error);
      }
    };
    const fetchAllOrigen = async () => {
      try {
        setIsLoading(true);
        const response = await getAllOrigen(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData = result?.data?.data || [];
          setOrigen(resultData);
        } else {
          handleErrorMessage();
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllOrigen();
    fetchAllPerfil();
  }, []);

  const handleRenderFlavorItem = async (backgroundColor) => {
    let isComunidadSelected = false;
    setCatacion((prevState) => {
      if (!prevState.comunidad) {
        handleMessage(
          'error',
          'Seleccione una comunidad.',
          'Favor de seleccionar una comunidad antes de asignar perfiles.',
        );
        setIsSubmitted(true);
      } else {
        isComunidadSelected = true;
      }
      return prevState;
    });

    if (backgroundColor && isComunidadSelected) {
      const selection = flavors.find((x) => x.hexcode === backgroundColor);
      if (selection?.nombre && selection?.hexcode) {
        setSelectedFlavors((prevState) => {
          const isAlreadySelected = prevState.some(
            (flavor) => flavor.hexcode === selection.hexcode,
          );
          if (!isAlreadySelected) {
            return [...prevState, selection];
          } else {
            handleMessage(
              'error',
              'Este perfil ya ha sido seleccionado.',
              'Favor de seleccionar otro.',
            );
          }
          return prevState;
        });
      }
    }
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!catacion.comunidad) {
      setIsLoading(false);
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de seleccionar una comunidad.',
      );
      return;
    }
    if (
      !catacion.puntaje &&
      typeof +catacion.puntaje === 'number' &&
      !isNaN(+catacion.puntaje)
    ) {
      setIsLoading(false);
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de registrar un puntaje al perfil.',
      );
      return;
    } else if (Math.abs(+catacion.puntaje) < 73) {
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de registrar un puntaje al perfil más alto o igual a 73.00.',
      );
      return;
    } else if (Math.abs(+catacion.puntaje) > 99) {
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de registrar un puntaje al perfil menor o igual a 99.00.',
      );
      return;
    }

    if (selectedFlavors.length > 0) {
      try {
        setIsLoading(true);
        setIsSubmitted(true);
        catacion.perfiles =
          selectedFlavors.map((obj) => obj.id).join(',') || [];
        const response = await addCatacion(TOKEN, USERID, catacion);
        if (response.status === 200) {
          setIsSubmitted(false);
          setCatacion(catacionInitialState);
          setSelectedFlavors([]);
          handleMessage('success', 'Operación exitosa.', CATACION.SUCCESS);
          window.scrollTo({ top: 0, left: 0 });
        } else {
          handleErrorMessage();
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSubmitted(true);
      handleErrorMessage();
    }
  };

  const handleErrorMessage = () => {
    handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      maxHeight={'85vh'}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)', '2xl': '720fr 350fr' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
          >
            {!isLoading && flavors?.length > 0 && (
              <FlavorWheel
                flavors={flavors}
                handleRenderFlavorItem={handleRenderFlavorItem}
              />
            )}
            {isLoading && (
              <Flex mb="5" justifyContent={'center'} alignItems={'center'}>
                <CircularProgress isIndeterminate color={textColorBrand} />
              </Flex>
            )}
          </Flex>
          <Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
            <MuestrasPanel
              selectedFlavors={selectedFlavors}
              setSelectedFlavors={setSelectedFlavors}
              catacion={catacion}
              setCatacion={setCatacion}
              origen={origen}
              handleFocus={handleFocus}
              handleSubmit={handleSubmit}
              isSubmitted={isSubmitted}
            />
          </Flex>
        </Grid>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
