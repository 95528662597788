// Chakra imports
import { Flex, Box, Icon, Text, Spacer } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

// Assets
import bgMastercard from 'assets/img/dashboards/Debit.png';
import { PiCoffee } from 'react-icons/pi';

export default function Perfil(props) {
  const { type, group, hexcode, name, ...rest } = props;

  // Chakra Color Mode
  return (
    <Card
      bgColor={hexcode || 'brand.600'}
      bgPosition="10%"
      alignSelf="center"
      w={{ base: '100%', md: '60%', xl: '99%' }}
      mx="auto"
      p="20px"
      {...rest}
    >
      <Flex direction="column" color="white" h="100%" w="100%">
        <Flex justify="space-between" align="center" mb="15px">
          <Text fontSize="2xl" fontWeight="bold">
            {name}.
          </Text>
          <Icon as={PiCoffee} w="48px" h="auto" color="white" />
        </Flex>

        <Flex direction="column">
          <Box>
            <Text fontSize={{ sm: 'xl', lg: 'lg', xl: 'xl' }} fontWeight="bold">
              {hexcode}
            </Text>
          </Box>
          <Flex mt="14px">
            <Flex direction="column" me="34px">
              <Text fontSize="xs">Característica</Text>
              <Text fontSize="sm" fontWeight="500">
                {type}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text fontSize="xs">Grupo</Text>
              <Text fontSize="sm" fontWeight="500">
                {group}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
