import { QuestionIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import Perfil from 'components/card/Perfil';
import FlavorItem from 'components/dataDisplay/FlavorItem';
import { CATACION } from 'constant/constant';
import { GiCoffeeBeans } from 'react-icons/gi';

export default function ComunidadesPanel({
  selectedFlavors,
  setSelectedFlavors,
  catacion,
  setCatacion,
  origen,
  handleFocus,
  isSubmitted,
  isLoading,
  handleSubmit,
}) {
  const iconColor = useColorModeValue('brand.500', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const handleComunidadSelection = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setCatacion((prevState) => ({
      ...prevState,
      folioSecado: 0,
      entradaId: 0,
      estado: selectedOption?.dataset?.estado || prevState.estado,
      region: selectedOption?.dataset?.region || prevState.region,
      municipio: selectedOption?.dataset?.municipality || prevState.municipio,
      comunidad: selectedOption?.dataset?.community || prevState.comunidad,
    }));
  };

  const handlePerfilRendering = (type, item) => {
    let flavor = item ? item : selectedFlavors[selectedFlavors.length - 1];

    switch (type) {
      case 'name': {
        return flavor?.nombre ? flavor.nombre : 'Nombre del perfil';
      }
      case 'hexcode': {
        return flavor?.hexcode ? flavor.hexcode : '#000';
      }
      case 'group': {
        const selectedFlavors = [
          flavor?.categoriaA,
          flavor?.categoriaB,
          flavor?.categoriaC,
          flavor?.categoriaD,
        ]
          .filter(Boolean)
          .join(', ');

        return selectedFlavors ? selectedFlavors : '-';
      }
      case 'type': {
        return flavor?.tipo ? flavor.tipo : '-';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Card maxHeight="85vh" overflowY="auto">
      <Flex justify="space-between" px="10px" pt="5px" mb="25px" align="center">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Selección de perfil
        </Text>
      </Flex>
      <Tabs>
        <TabPanels mb="20px">
          <TabPanel p="0px">
            <Perfil
              name={handlePerfilRendering('name')}
              hexcode={handlePerfilRendering('hexcode')}
              group={handlePerfilRendering('group')}
              type={handlePerfilRendering('type')}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '1', lg: '1' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'2rem'}
        >
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <Tooltip
                label={CATACION.COMUNIDAD_TOOLTIP}
                aria-label="A tooltip"
              >
                <FormLabel
                  display="flex"
                  alignItems="center"
                  ms="4px"
                  fontSize="16px"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  gap="10px"
                >
                  Comunidad <QuestionIcon color={textColor} />
                </FormLabel>
              </Tooltip>
            </Flex>
            <Select
              borderColor={
                isSubmitted && !catacion.comunidad ? 'red.500' : null
              }
              value={catacion.comunidad || ''}
              onChange={(e) => {
                handleComunidadSelection(e);
              }}
              onFocus={handleFocus}
              variant="auth"
              placeholder="Comunidad"
            >
              {origen.length > 0 &&
                origen
                  .filter((x) => x.comunidad)
                  .map((x) => (
                    <option
                      key={x.comunidad}
                      value={x.comunidad}
                      data-estado={x.estado || 'No especificado'}
                      data-region={x.region || 'No especificado'}
                      data-municipality={x.municipio || 'No especificado'}
                      data-community={x.comunidad || 'No especificado'}
                    >
                      {x.comunidad}
                    </option>
                  ))}
            </Select>
          </Flex>
        </SimpleGrid>
      </FormControl>
      <Text fontSize="xl" color={textColor} fontWeight="700" mb="34px">
        Aromas | Sabores identificados
      </Text>
      {selectedFlavors?.length > 0 && (
        <Flex justify="center" mb={'15px'}>
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            loadingText="Realizando registro..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Registrar perfil
          </Button>
        </Flex>
      )}
      <Flex flexDirection="column" justify="flex-start">
        {selectedFlavors?.map((x) => {
          return (
            x.nombre &&
            x.hexcode && (
              <FlavorItem
                key={x.hexcode}
                mb="26px"
                name={x.nombre}
                specification={`${handlePerfilRendering(
                  'type',
                  x,
                )} | ${handlePerfilRendering('group', x)}`}
                selectedFlavors={selectedFlavors}
                setSelectedFlavors={setSelectedFlavors}
                icon={
                  <Icon
                    as={GiCoffeeBeans}
                    color={iconColor}
                    w="20px"
                    h="18px"
                  />
                }
              />
            )
          );
        })}
      </Flex>

      {selectedFlavors?.length === 0 && (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
          height="100%"
        >
          <Icon as={GiCoffeeBeans} color={iconColor} w="40px" h="40px" />
          <Text
            fontSize="sm"
            color={textColor}
            fontWeight="500"
            mb="34px"
            textAlign="center"
          >
            No hay valores registrados aún.
          </Text>
        </Flex>
      )}
    </Card>
  );
}
