import { Button, Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { getAllOrdenMaquila } from 'api/maquila';
import { addSalida, getLatestId } from 'api/salida';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { MdOutlineDriveFileMove } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Cliente from 'views/admin/seco/salidas/components/Cliente';
import Salida from 'views/admin/seco/salidas/components/Salida';
import PrintableContent from 'views/admin/seco/salidas/PrintableContent';

export default function SalidaRegistro() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const { tipo, caracteristica } = useSelector((state) => state.cafe);
  const location = useLocation();
  const { selectedSalida, previewMode } = location.state || {};

  const initialStateSalida = {
    salidaId: '',
    fecha: new Date(),
    tipo: '',
    cosecha: '',
    direccion: '',
    ciudad: '',
  };

  const initialStateWeightExits = [
    {
      id: 1,
      folioMaquila: '',
      numeroIco: '',
      estado: '',
      region: '',
      comunidad: '',
      municipio: '',
      productor: '',
      caracteristica: '',
      chofer: '',
      placas: '',
      marca: '',
      unidad: '',
      cumple: 'Cumple',
      sacosTotal: '',
      kilosBrutoTotal: '',
      kilosNetoTotal: '',
      precioLibra: '',
      observaciones: '',
    },
  ];

  const [maquila, setMaquila] = useState([]);
  const [weightExits, setWeightExits] = useState(initialStateWeightExits);
  const [salida, setSalida] = useState(initialStateSalida);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const fetchLatestId = async () => {
      const latestId = await getLatestId(TOKEN);
      if (latestId !== null && latestId !== undefined) {
        setSalida((prevState) => ({ ...prevState, salidaId: latestId + 1 }));
      } else {
        handleMessage('error', ALERT.TITLE, ALERT.SUBTITLE);
      }
    };

    const fetchAllMaquila = async () => {
      try {
        setIsLoading(true);
        const response = await getAllOrdenMaquila(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData =
            result?.data?.data.filter((x) => x.estatus !== 'salida') || [];
          setMaquila(resultData);
        } else {
          handleMessage('error', ALERT.TITLE, ALERT.SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.TITLE, ALERT.SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLatestId();
    fetchAllMaquila();
  }, []);

  const handleWeightExitChange = (field, value, id) => {
    setWeightExits((prevState) => {
      return prevState.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
    });
  };

  const handleChange = (field, value) => {
    setSalida((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleAllTruthy = (obj, exceptions = []) => {
    const arrayOfObjects = Array.isArray(obj) ? obj : [obj];
    const values = arrayOfObjects.flatMap((item) => {
      const keys = Object.keys(item);
      const filteredKeys = keys.filter((key) => !exceptions.includes(key));
      return filteredKeys.map((key) => item[key]);
    });

    if (!values || values.length === 0) {
      return false;
    } else {
      return values.every((value) => !!value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const exceptions = ['observaciones'];
    const allSalidaPropertiesTruthy = handleAllTruthy(salida, exceptions);
    const allWeightExitPropertiesTruthy = handleAllTruthy(
      weightExits,
      exceptions,
    );

    if (allSalidaPropertiesTruthy && allWeightExitPropertiesTruthy) {
      try {
        setIsLoading(true);
        setIsSubmitted(true);
        for (let exit of weightExits) {
          const submitSalida = { ...salida, ...exit };
          const response = await addSalida(TOKEN, USERID, submitSalida);
          if (response.status === 200) {
            setIsSubmitted(false);
            setIsLoading(false);
            handleMessage(
              'success',
              ALERT.SUCCESS_TITLE,
              ALERT.SUCCESS_SUBTITLE,
            );
            setWeightExits(initialStateWeightExits);
            setSalida((prevState) => ({
              ...initialStateSalida,
              salidaId: prevState.salidaId + 1,
            }));
            window.scrollTo({ top: 0, left: 0 });
          } else {
            setIsLoading(false);
            handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
          }
        }
      } catch (error) {
        setIsLoading(false);
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      }
    } else {
      setIsSubmitted(true);
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
    }
  };

  return !previewMode ? (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Cliente
              salida={salida}
              isSubmitted={isSubmitted}
              handleChange={handleChange}
              handleFocus={handleFocus}
              tipo={tipo}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Salida
              key={salida.salidaId}
              maquila={maquila}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleWeightExitChange={handleWeightExitChange}
              weightExits={weightExits}
              setWeightExits={setWeightExits}
              caracteristica={caracteristica}
            />
          </Flex>
        </Grid>

        <Flex justify="center" my="40px">
          <Button
            onClick={handleSubmit}
            leftIcon={<MdOutlineDriveFileMove />}
            isLoading={isLoading}
            loadingText="Realizando registro..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Registrar salida
          </Button>
        </Flex>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  ) : (
    <PrintableContent
      selectedSalida={selectedSalida}
      previewMode={previewMode}
    />
  );
}
