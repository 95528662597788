import { Button, Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import PesoPrintable from 'views/admin/seco/entradas/printable/PesoPrintable';
import ProveedorPrintable from 'views/admin/seco/entradas/printable/ProveedorPrintable';
import '../../../../assets/css/Print.css';
import FooterPrintable from '../../../../components/printable/FooterPrintable';
import HeaderPrintable from '../../../../components/printable/HeaderPrintable';

const PrintableContent = ({ selectedEntrada, previewMode }) => {
  const navigate = useNavigate();
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const contentToPrintRef = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: 'Reporte de entrada',
    removeAfterPrint: true,
    content: () => contentToPrintRef.current,
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Flex
      direction={{ base: 'column' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex
        ref={contentToPrintRef}
        direction="column"
        width="stretch"
        className="printable-content"
      >
        <HeaderPrintable title="Reporte de entrada" />
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <ProveedorPrintable
              entrada={selectedEntrada}
              previewMode={previewMode}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <PesoPrintable
              weightEntries={[selectedEntrada]}
              previewMode={previewMode}
            />
          </Flex>
        </Grid>
        <FooterPrintable />
      </Flex>
      <Flex justify="center" my="40px" gap="20px">
        <Button
          onClick={handleGoBack}
          bgColor="gray.500"
          color="white"
          size="lg"
        >
          Volver
        </Button>
        <Button
          onClick={handlePrint}
          loadingText="Imprimiendo..."
          bgColor={balanceBg}
          color="white"
          size="lg"
        >
          Imprimir
        </Button>
      </Flex>
    </Flex>
  );
};

export default PrintableContent;
