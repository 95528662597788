import { Divider, FormControl } from '@chakra-ui/react';

import Entrada from 'views/admin/seco/entradas/components/Peso por unidad o transporte/components/components/Entrada';
import Header from 'views/admin/seco/entradas/components/Peso por unidad o transporte/components/components/Header';
import Observaciones from 'views/admin/seco/entradas/components/Peso por unidad o transporte/components/components/Observaciones';
import Vehiculo from 'views/admin/seco/entradas/components/Peso por unidad o transporte/components/components/Vehiculo';

export default function PesoForm({
  id,
  weightEntry,
  isSubmitted,
  handleFocus,
  handleWeightEntryChange,
  deleteEntry,
  caracteristica,
}) {
  const handlePeso = (
    pesoConCarga = 0,
    pesoSinCarga = 0,
    yutes = 0,
    lonas = 0,
    cajas = 0,
  ) => {
    let fieldsComplete = weightEntry.pesoConCarga && weightEntry.pesoSinCarga;

    let taraWeight =
      (+yutes || +weightEntry.yutes) * 1 +
      (+lonas || +weightEntry.lonas) * 0.1 +
      (+cajas || +weightEntry.cajas) * 4;

    let taraQuantity =
      (+yutes || +weightEntry.yutes) +
      (+lonas || +weightEntry.lonas) +
      (+cajas || +weightEntry.cajas);

    let pesoNetoValue = fieldsComplete
      ? (pesoConCarga || +weightEntry.pesoConCarga) -
        (pesoSinCarga || +weightEntry.pesoSinCarga) -
        taraWeight
      : 0;

    let pesoPorSaco = taraQuantity ? pesoNetoValue / taraQuantity : 0;

    handleWeightEntryChange('pesoNeto', pesoNetoValue, id);
    handleWeightEntryChange('tara', taraWeight, id);
    handleWeightEntryChange('pesoPorSaco', pesoPorSaco, id);
    handleTotal(0, 0, pesoNetoValue);
  };

  const handlePesoBruto = (pesoConCarga = 0, pesoSinCarga = 0) => {
    const value =
      (+pesoConCarga || +weightEntry.pesoConCarga) -
      (+pesoSinCarga || +weightEntry.pesoSinCarga);

    handleWeightEntryChange('pesoBruto', value, id);
  };

  const handleTotal = (precioKg = 0, porcentajeHumedad = 0, pesoNeto = 0) => {
    const humedad = +porcentajeHumedad || +weightEntry.humedad;
    const neto = +pesoNeto || +weightEntry.pesoNeto;
    const precio = +precioKg || +weightEntry.precioKg;

    let humidityDiscount = 0;
    let humidityWeight = 0;

    const isHumedadValid = humedad > 11;

    if (isHumedadValid) {
      const humiditySurplus = humedad - 11;

      humidityWeight = (neto * humiditySurplus) / 100;
      humidityDiscount = Math.round(humidityWeight * precio);

      handleWeightEntryChange('descuentoHumedad', humidityDiscount, id);
      handleWeightEntryChange('descuentoHumedadPeso', humidityWeight, id);
    } else {
      handleWeightEntryChange('descuentoHumedad', 0, id);
      handleWeightEntryChange('descuentoHumedadPeso', 0, id);
    }

    if (precio && neto) {
      const precioPrevio = precio * neto;
      const precioTotal = precioPrevio - humidityDiscount;

      handleWeightEntryChange('precioPrevio', precioPrevio, id);
      handleWeightEntryChange('precioTotal', precioTotal, id);
    }
  };

  return (
    <FormControl id={id}>
      <Header id={id} deleteEntry={deleteEntry} />
      <Divider orientation="horizontal" my={'2rem'} />
      <Vehiculo
        id={id}
        weightEntry={weightEntry}
        isSubmitted={isSubmitted}
        handleFocus={handleFocus}
        handleWeightEntryChange={handleWeightEntryChange}
      />
      <Divider orientation="horizontal" my={'2rem'} />
      <Entrada
        id={id}
        weightEntry={weightEntry}
        isSubmitted={isSubmitted}
        handleFocus={handleFocus}
        handleWeightEntryChange={handleWeightEntryChange}
        caracteristica={caracteristica}
        handlePeso={handlePeso}
        handlePesoBruto={handlePesoBruto}
        handleTotal={handleTotal}
      />
      <Observaciones
        id={id}
        weightEntry={weightEntry}
        handleFocus={handleFocus}
        handleWeightEntryChange={handleWeightEntryChange}
      />
      <Divider orientation="horizontal" my={'2rem'} />
    </FormControl>
  );
}
