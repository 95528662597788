import {
  Button,
  CircularProgress,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import AsignClient from './components/AsignClient';
import Confirm from './components/Confirm';
import SelectOption from './components/SelectOption';

export default function ChangeStatus({ isOpen, onClose, message, setRefetch }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [statusChanged, setStatusChanged] = useState(false);
  const [confirm, setConfirm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const handleClose = () => {
    setSelectedOption('');
    onClose();
    if (statusChanged) {
      setRefetch((prevState) => !prevState);
      setStatusChanged(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxWidth={'800px'}>
        <ModalCloseButton />
        {isLoading && (
          <Flex
            width={'100%'}
            height={'200px'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'10px'}
          >
            <Text>Cargando...</Text>
            <CircularProgress isIndeterminate color={boxBg} size={50} />
          </Flex>
        )}

        {!selectedOption && !isLoading && (
          <SelectOption
            message={message}
            setSelectedOption={setSelectedOption}
            setConfirm={setConfirm}
            setIsLoading={setIsLoading}
            setStatusChanged={setStatusChanged}
          />
        )}
        {selectedOption === 'oferta' && !isLoading && (
          <AsignClient
            message={message}
            setSelectedOption={setSelectedOption}
            setConfirm={setConfirm}
            setIsLoading={setIsLoading}
            setStatusChanged={setStatusChanged}
          />
        )}
        {selectedOption === 'confirm' && !isLoading && (
          <Confirm message={message} confirm={confirm} />
        )}
        <ModalFooter>
          <Button onClick={handleClose}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
