// Chakra imports
import {
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { FaRegUser } from 'react-icons/fa';

export default function ProveedorPrintable({ entrada, previewMode }) {
  const balanceBg = useColorModeValue('brand.900', 'white');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const entradaVariables = [
    { title: 'Proveedor', value: entrada?.productor || 'No especificado' },
    { title: 'Estado', value: entrada?.estado || 'No especificado' },
    { title: 'Región', value: entrada?.region || 'No especificado' },
    { title: 'Municipio', value: entrada?.municipio || 'No especificado' },
    { title: 'Comunidad', value: entrada?.comunidad || 'No especificado' },
    { title: 'Cosecha', value: entrada?.cosecha || 'No especificado' },
    { title: 'Tipo de café', value: entrada?.tipo || 'No especificado' },
  ];

  // Preview mode for "subregistros" values
  if (previewMode) {
    entradaVariables.push({
      title: 'Entrada ID',
      value: entrada?.registerId || 'No especificado',
    });
  }

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        borderWidth="2px"
        borderColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
        className="printable-subheader"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex gap="15px" flexDirection="row" me="14px">
            <FaRegUser color={balanceBg} size={'20px'} />
            <Text
              color={balanceBg}
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Datos del cliente o proveedor
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '4', md: '4', lg: '4' }}
          gap={{ sm: '10px', md: '26px' }}
          px={'2rem'}
        >
          {entradaVariables.map((x) => (
            <Flex direction="column" mb={'1rem'}>
              <Flex>
                <FormLabel
                  color={textColor}
                  display="flex"
                  fontSize="sm"
                  fontWeight="500"
                >
                  {x.title}
                </FormLabel>
              </Flex>
              <Text>{x.value}</Text>
            </Flex>
          ))}
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
