export const tableColumnsSecado = [
  {
    headerName: 'Folio',
    field: 'folio',
    width: 200,
  },
  {
    headerName: 'Entrada',
    field: 'entradaId',
    width: 100,
  },
  {
    headerName: 'Fecha',
    field: 'fecha',
    dataType: 'date',
    width: 150,
  },
  {
    headerName: 'Clave descarga',
    field: 'claveDescarga',
    width: 200,
  },
  {
    headerName: 'Región',
    field: 'region',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Municipio',
    field: 'municipio',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Comunidad',
    field: 'comunidad',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Productor',
    field: 'productor',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Tipo',
    field: 'tipo',
    width: 200,
  },
  {
    headerName: 'Característica',
    field: 'caracteristica',
    width: 200,
  },
  {
    headerName: 'Pergamino',
    field: 'pergamino',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Pergaminera',
    field: 'pergaminera',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Merma',
    field: 'merma',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Peso total',
    field: 'pesoTotal',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Pergamino total',
    field: 'pergaminoTotal',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Pergaminera total',
    field: 'pergamineraTotal',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Merma total',
    field: 'mermaTotal',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Observaciones',
    field: 'observaciones',
    width: 200,
  },
];
