import { createSlice } from '@reduxjs/toolkit';

const initialOrigenState = {
  productor: [],
  vehiculo: [],
  origen: [],
};

const origenSlice = createSlice({
  name: 'origen',
  initialState: initialOrigenState,
  reducers: {
    setProductorData(state, action) {
      state.productor = action.payload.productor;
    },
    setVehiculoData(state, action) {
      state.vehiculo = action.payload.vehiculo;
    },
    setOrigenData(state, action) {
      state.origen = action.payload.origen;
    },
  },
});

export const origenActions = origenSlice.actions;
export default origenSlice.reducer;
