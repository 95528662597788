export const tableColumnsSalida = [
  {
    headerName: 'ID',
    field: 'registerId',
    width: 100,
  },
  {
    headerName: 'Salida',
    field: 'salidaId',
    width: 100,
  },
  {
    headerName: 'Cliente',
    field: 'cliente',
    width: 200,
  },
  {
    headerName: 'Folio de maquila',
    field: 'folioMaquila',
    dataType: 'maquila',
    width: 200,
  },
  {
    headerName: 'Fecha',
    field: 'fecha',
    dataType: 'date',
    width: 200,
  },
  {
    headerName: 'Región',
    field: 'region',
    width: 200,
  },
  {
    headerName: 'Comunidad',
    field: 'comunidad',
    width: 200,
  },
  {
    headerName: 'Productor',
    field: 'productor',
    width: 200,
  },
  {
    headerName: 'Tipo',
    field: 'tipo',
    width: 200,
  },
  {
    headerName: 'Chofer',
    field: 'chofer',
    width: 200,
  },
  {
    headerName: 'Característica',
    field: 'caracteristica',
    width: 200,
  },
  { headerName: 'Ciudad destino', field: 'ciudad', width: 200 },
  { headerName: 'Dirección destino', field: 'direccion', width: 200 },
  {
    headerName: 'Sacos total',
    field: 'sacosTotal',
    dataType: 'numeric',
    width: 200,
  },
  {
    headerName: 'Peso bruto',
    field: 'kilosBrutoTotal',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Peso neto',
    field: 'kilosNetoTotal',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Precio por libra',
    field: 'precioLibra',
    dataType: 'pesos',
    width: 200,
  },
  {
    headerName: 'Observaciones',
    field: 'observaciones',
    width: 200,
  },
];
