import {
  CircularProgress,
  Flex,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getAllPerfil } from 'api/cafe';
import { addCatacion, getAllCatacion } from 'api/catacion';
import { getAllSecado } from 'api/secado';
import SelectCommunityPerfil from 'components/modal/communityPerfil/SelectCommunityPerfil';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { ALERT, CATACION } from 'constant/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FlavorWheel from './components/FlavorWheel';
import SelectionsPanel from './components/SelectionsPanel';

export default function CatacionFolios() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const catacionInitialState = {
    folioSecado: '',
    claveDescarga: '',
    entradaId: '',
    fecha: '',
    puntaje: '',
    perfiles: '',
    estado: '',
    region: '',
    municipio: '',
    comunidad: '',
    productor: '',
    tipo: '',
    cliente: '',
    caracteristica: '',
    clasificacion: 'folio',
    createdAt: '',
    createdBy: '',
  };

  const [flavors, setFlavors] = useState([]);
  const [selectedFlavors, setSelectedFlavors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [catacion, setCatacion] = useState(catacionInitialState);
  const [secado, setSecado] = useState([]);
  const [perfilsByCommunity, setPerfilsByCommunity] = useState([]);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });
  const [communityModal, setCommunityModal] = useState({
    state: false,
    title: '',
    subtitle: '',
    communities: [],
  });

  useEffect(() => {
    const fetchAllPerfil = async () => {
      try {
        setIsLoading(true);
        const response = await getAllPerfil(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData = result?.data?.data || [];
          setFlavors(resultData);
        } else {
          handleErrorMessage();
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchAllSecado = async () => {
      try {
        setIsLoading(true);
        const response = await getAllSecado(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData =
            result?.data?.data.filter(
              (x) => x.estatus !== 'salida' && x.estatus !== 'maquila',
            ) || [];
          setSecado(resultData);
        } else {
          handleErrorMessage();
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchPerfilsByCommunity = async () => {
      try {
        setIsLoading(true);
        const response = await getAllCatacion(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData =
            result?.data?.data.filter((x) => x.folioSecado === 0) || [];
          setPerfilsByCommunity(resultData);
        } else {
          handleErrorMessage();
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllSecado();
    fetchAllPerfil();
    fetchPerfilsByCommunity();
  }, []);

  const handleRenderFlavorItem = async (backgroundColor) => {
    let isFolioSelected = false;
    setCatacion((prevState) => {
      if (!prevState.folioSecado) {
        handleMessage(
          'error',
          'Seleccione un folio de secado.',
          'Favor de seleccionar un folio antes de asignar perfiles.',
        );
        setIsSubmitted(true);
      } else {
        isFolioSelected = true;
      }
      return prevState;
    });

    if (backgroundColor && isFolioSelected) {
      const selection = flavors.find((x) => x.hexcode === backgroundColor);
      if (selection?.nombre && selection?.hexcode) {
        setSelectedFlavors((prevState) => {
          const isAlreadySelected = prevState.some(
            (flavor) => flavor.hexcode === selection.hexcode,
          );
          if (!isAlreadySelected) {
            return [...prevState, selection];
          } else {
            handleMessage(
              'error',
              'Este perfil ya ha sido seleccionado.',
              'Favor de seleccionar otro.',
            );
          }
          return prevState;
        });
      }
    }
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!catacion.folioSecado) {
      setIsLoading(false);
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de seleccionar un folio de secado.',
      );
      return;
    }
    if (
      !catacion.puntaje &&
      typeof +catacion.puntaje === 'number' &&
      !isNaN(+catacion.puntaje)
    ) {
      setIsLoading(false);
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de registrar un puntaje al perfil.',
      );
      return;
    } else if (Math.abs(+catacion.puntaje) < 73) {
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de registrar un puntaje al perfil más alto o igual a 73.00.',
      );
      return;
    } else if (Math.abs(+catacion.puntaje) > 99) {
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de registrar un puntaje al perfil menor o igual a 99.00.',
      );
      return;
    }

    if (selectedFlavors.length > 0) {
      try {
        setIsLoading(true);
        setIsSubmitted(true);
        catacion.perfiles =
          selectedFlavors.map((obj) => obj.id).join(',') || [];
        const response = await addCatacion(TOKEN, USERID, catacion);
        if (response.status === 200) {
          setIsSubmitted(false);
          setCatacion(catacionInitialState);
          setSelectedFlavors([]);
          handleMessage('success', 'Operación exitosa.', CATACION.SUCCESS);
          window.scrollTo({ top: 0, left: 0 });
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSubmitted(true);
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
    }
  };

  const handleCloseCommunityModal = () => {
    setCommunityModal({
      state: false,
      title: '',
      subtitle: '',
      communities: [],
    });
  };

  const handleErrorMessage = () => {
    handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      maxHeight={'85vh'}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)', '2xl': '720fr 350fr' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
          >
            {!isLoading && flavors?.length > 0 && (
              <FlavorWheel
                flavors={flavors}
                handleRenderFlavorItem={handleRenderFlavorItem}
              />
            )}
            {isLoading && (
              <Flex mb="5" justifyContent={'center'} alignItems={'center'}>
                <CircularProgress isIndeterminate color={textColorBrand} />
              </Flex>
            )}
          </Flex>
          <Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
            <SelectionsPanel
              selectedFlavors={selectedFlavors}
              setSelectedFlavors={setSelectedFlavors}
              catacion={catacion}
              setCatacion={setCatacion}
              secado={secado}
              handleFocus={handleFocus}
              handleSubmit={handleSubmit}
              isSubmitted={isSubmitted}
              setCommunityModal={setCommunityModal}
              perfilsByCommunity={perfilsByCommunity}
            />
          </Flex>
        </Grid>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
      <SelectCommunityPerfil
        isOpen={communityModal.state}
        onClose={handleCloseCommunityModal}
        communityModal={communityModal}
        setSelectedFlavors={setSelectedFlavors}
        availableFlavors={flavors}
      />
    </Flex>
  );
}
