import PropTypes from 'prop-types';

// Chakra imports
import {
  Flex,
  Link,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import { SidebarContext } from 'contexts/SidebarContext';
// Assets

export default function AuthNavbar(props) {
  const { logo, logoText, secondary, sidebarWidth } = props;
  const { colorMode } = useColorMode();

  const textColor = useColorModeValue('navy.700', 'white');
  let mainText = '#000';
  let navbarBg = 'none';
  let navbarShadow = 'initial';

  let brand = (
    <Link
      href={`${process.env.PUBLIC_URL}/#/`}
      target="_blank"
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Stack direction="row" spacing="12px" align="center" justify="center">
        <Text color={textColor} fontSize="15px" mt="3px">
          {logoText}
        </Text>
      </Stack>
    </Link>
  );

  return (
    <SidebarContext.Provider value={{ sidebarWidth }}>
      <Flex
        top="16px"
        background={navbarBg}
        boxShadow={navbarShadow}
        borderRadius="15px"
        px="16px"
        py="22px"
        mx="auto"
        width="100%"
        alignItems="center"
        zIndex="3"
      >
        <Flex w="100%" justifyContent="center">
          {brand}
        </Flex>
      </Flex>
    </SidebarContext.Provider>
  );
}

AuthNavbar.propTypes = {
  brandText: PropTypes.string,
};
