import { createSlice } from '@reduxjs/toolkit';

const initialPerfilState = {
  perfil: [],
};

const perfilSlice = createSlice({
  name: 'perfil',
  initialState: initialPerfilState,
  reducers: {
    setPerfilData(state, action) {
      state.perfil = action.payload.perfil;
    },
  },
});

export const perfilActions = perfilSlice.actions;
export default perfilSlice.reducer;
