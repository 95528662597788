import {
  CircularProgress,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { getAllRendimiento } from 'api/rendimiento';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import DataTable from 'components/table/dataTable';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { tableColumnsRendimiento } from './variables/tableColumnsRendimiento';

export default function RendimientoBaseDatos() {
  const TOKEN = useSelector((state) => state.auth.tokenId);

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const [rendimientoTableData, setRendimientoTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  useEffect(() => {
    const fetchAllRendimiento = async () => {
      try {
        setIsLoading(true);
        const response = await getAllRendimiento(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData = result?.data?.data || [];
          setRendimientoTableData(resultData);
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllRendimiento();
  }, []);

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      {isLoading && (
        <Flex
          width={'100%'}
          height={'80vh'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          gap={'10px'}
        >
          <Text>Cargando...</Text>
          <CircularProgress isIndeterminate color={balanceBg} size={100} />{' '}
        </Flex>
      )}
      {!isLoading && (
        <Flex direction="column" width="stretch">
          <Grid
            templateColumns={{ base: 'repeat(2, 1fr)' }}
            gap="20px"
            display={{ base: 'block', lg: 'grid' }}
          >
            <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
              {rendimientoTableData && (
                <DataTable
                  rows={rendimientoTableData}
                  columns={tableColumnsRendimiento}
                />
              )}
            </Flex>
          </Grid>
        </Flex>
      )}
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
