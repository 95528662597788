import {
  CircularProgress,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { getAllPerfil } from 'api/cafe';
import { getAllCatacion } from 'api/catacion';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import DataTable from 'components/table/dataTable';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { tableColumnsCatacion } from 'views/admin/seco/catacion/variables/tableColumnsCatacion';

export default function BaseDatos() {
  const TOKEN = useSelector((state) => state.auth.tokenId);

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const [catacionTableData, setCatacionTableData] = useState(null);
  const [perfil, setPerfil] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const formattedTableData = catacionTableData?.map((tableData) => {
    const perfilNames = [];
    const perfilNumericArr = tableData?.perfiles
      ? tableData.perfiles.split(',')
      : [];

    for (let perfilNum of perfilNumericArr) {
      const perfilName = perfil.find((x) => x.id.toString() === perfilNum);
      if (perfilName?.nombre) {
        perfilNames.push(perfilName.nombre);
      }
    }
    return {
      ...tableData,
      perfiles:
        perfilNames.length > 0 ? perfilNames.join(', ') : 'No especificado',
    };
  });

  useEffect(() => {
    const fetchAllPerfil = async () => {
      try {
        setIsLoading(true);
        const response = await getAllPerfil(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData = result?.data?.data || [];
          setPerfil(resultData);
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchAllCatacion = async () => {
      try {
        setIsLoading(true);
        const response = await getAllCatacion(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData = result?.data?.data || [];
          setCatacionTableData(resultData);
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllPerfil();
    fetchAllCatacion();
  }, []);

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      {isLoading && (
        <Flex
          width={'100%'}
          height={'80vh'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          gap={'10px'}
        >
          <Text>Cargando...</Text>
          <CircularProgress isIndeterminate color={balanceBg} size={100} />{' '}
        </Flex>
      )}
      {!isLoading && (
        <Flex direction="column" width="stretch">
          <Grid
            templateColumns={{ base: 'repeat(2, 1fr)' }}
            gap="20px"
            display={{ base: 'block', lg: 'grid' }}
          >
            <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
              {catacionTableData && perfil && (
                <DataTable
                  columns={tableColumnsCatacion}
                  rows={formattedTableData}
                  sortModel={[{ field: 'folioSecado', sort: 'desc' }]}
                />
              )}
            </Flex>
          </Grid>
        </Flex>
      )}
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
