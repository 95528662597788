import { createSlice } from '@reduxjs/toolkit';

const initialCafeState = {
  tipo: [],
  caracteristica: [],
};

const cafeSlice = createSlice({
  name: 'cafe',
  initialState: initialCafeState,
  reducers: {
    setTipoData(state, action) {
      state.tipo = action.payload.tipo;
    },
    setCaracteristicaData(state, action) {
      state.caracteristica = action.payload.caracteristica;
    },
  },
});

export const cafeActions = cafeSlice.actions;
export default cafeSlice.reducer;
