// Chakra imports
import {
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';

import { extractNumericValue } from 'utils/format';

export default function SecadoForm({
  secadoEntry,
  setSecado,
  isSubmitted,
  handleFocus,
  handleSecadoEntryChange,
}) {
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const handleMerma = (pergamino, pergaminera) => {
    let merma = 0;
    if (pergamino !== '' && pergaminera !== '') {
      merma = (
        +secadoEntry.peso -
        (+pergamino || +secadoEntry.pergamino) -
        (+pergaminera || +secadoEntry.pergaminera)
      ).toFixed(2);
    } else if (pergamino === '') {
      merma = (
        +secadoEntry.peso - (+pergaminera || +secadoEntry.pergaminera)
      ).toFixed(2);
    } else if (pergaminera === '') {
      merma = (
        +secadoEntry.peso - (+pergamino || +secadoEntry.pergamino)
      ).toFixed(2);
    } else {
      merma = (+secadoEntry.peso).toFixed(2);
    }
    handleSecadoEntryChange('merma', merma, secadoEntry.id);
    setSecado((prevState) => ({
      ...prevState,
      mermaTotal: (
        (+prevState.mermaTotal || 0) -
        (+secadoEntry.merma || 0) +
        +merma
      ).toFixed(2),
    }));
  };

  return (
    <SimpleGrid
      columns={{ base: '1', md: '3', lg: '6' }}
      gap={{ sm: '10px', md: '26px' }}
      mb={'1rem'}
    >
      <Flex direction="column">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Folio
        </FormLabel>
        <InputGroup>
          <Input
            disabled
            value={secadoEntry.folio}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Folio"
            variant="auth"
            size="lg"
          />
        </InputGroup>
      </Flex>
      <Flex direction="column">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Clave
        </FormLabel>
        <InputGroup>
          <Input
            disabled
            value={secadoEntry.claveDescarga}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Clave de descarga"
            variant="auth"
            size="lg"
          />
        </InputGroup>
      </Flex>
      <Flex direction="column">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Peso
        </FormLabel>
        <InputGroup>
          <Input
            borderColor={isSubmitted && !secadoEntry.peso ? 'red.500' : null}
            value={secadoEntry.peso}
            onChange={(e) => {
              const value = extractNumericValue(e.target.value);
              handleSecadoEntryChange('peso', value, secadoEntry.id);
              setSecado((prevState) => ({
                ...prevState,
                pesoTotal: (
                  (+prevState.pesoTotal || 0) -
                  (+secadoEntry.peso || 0) +
                  +value
                ).toFixed(2),
              }));
            }}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Peso"
            variant="auth"
            size="lg"
          />
          <InputRightAddon color={textColor}>KG</InputRightAddon>
        </InputGroup>
      </Flex>
      <Flex direction="column">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Pergamino
        </FormLabel>
        <InputGroup>
          <Input
            borderColor={
              isSubmitted && !secadoEntry.pergamino ? 'red.500' : null
            }
            value={secadoEntry.pergamino}
            onChange={(e) => {
              const value = extractNumericValue(e.target.value);
              handleMerma(value, false);
              handleSecadoEntryChange('pergamino', value, secadoEntry.id);
              setSecado((prevState) => ({
                ...prevState,
                pergaminoTotal: (
                  (+prevState.pergaminoTotal || 0) -
                  (+secadoEntry.pergamino || 0) +
                  +value
                ).toFixed(2),
              }));
            }}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Pergamino"
            variant="auth"
            size="lg"
          />
          <InputRightAddon color={textColor}>KG</InputRightAddon>
        </InputGroup>
      </Flex>
      <Flex direction="column">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Pergaminera
        </FormLabel>
        <InputGroup>
          <Input
            borderColor={
              isSubmitted && !secadoEntry.pergaminera ? 'red.500' : null
            }
            value={secadoEntry.pergaminera}
            onChange={(e) => {
              const value = extractNumericValue(e.target.value);
              handleMerma(false, value);
              handleSecadoEntryChange('pergaminera', value, secadoEntry.id);
              setSecado((prevState) => ({
                ...prevState,
                pergamineraTotal: (
                  (+prevState.pergamineraTotal || 0) -
                  (+secadoEntry.pergaminera || 0) +
                  +value
                ).toFixed(2),
              }));
            }}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Pergaminera"
            variant="auth"
            size="lg"
          />
          <InputRightAddon color={textColor}>KG</InputRightAddon>
        </InputGroup>
      </Flex>
      <Flex direction="column">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Merma
        </FormLabel>
        <InputGroup>
          <Input
            borderColor={isSubmitted && !secadoEntry.merma ? 'red.500' : null}
            value={secadoEntry.merma}
            onChange={(e) => {
              const value = extractNumericValue(e.target.value);
              handleSecadoEntryChange('merma', value, secadoEntry.id);
              setSecado((prevState) => ({
                ...prevState,
                mermaTotal: (
                  (+prevState.mermaTotal || 0) -
                  (+secadoEntry.merma || 0) +
                  +value
                ).toFixed(2),
              }));
            }}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Merma"
            variant="auth"
            size="lg"
          />
          <InputRightAddon color={textColor}>KG</InputRightAddon>
        </InputGroup>
      </Flex>
    </SimpleGrid>
  );
}
