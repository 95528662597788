import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  SimpleGrid,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

import { useState } from 'react';

// Custom components
import { QuestionIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';
import Perfil from 'components/card/Perfil';
import FlavorItem from 'components/dataDisplay/FlavorItem';
import { CATACION } from 'constant/constant';
import { GiCoffeeBeans } from 'react-icons/gi';
import { extractNumericValue } from 'utils/format';

export default function SelectionsPanel({
  selectedFlavors,
  setSelectedFlavors,
  catacion,
  setCatacion,
  secado,
  handleFocus,
  isSubmitted,
  isLoading,
  handleSubmit,
  setCommunityModal,
  perfilsByCommunity,
}) {
  const iconColor = useColorModeValue('brand.500', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const buttonBgColor = useColorModeValue('gray.200', 'gray.600');
  const buttonActiveBgColor = useColorModeValue('gray.400', 'gray.800');
  const buttonColor = useColorModeValue('black', 'white');

  const [prefix, setPrefix] = useState('+');

  const handlePrefixChange = (newPrefix) => {
    setPrefix(newPrefix);
    setCatacion((prevState) => ({
      ...prevState,
      puntaje: `${newPrefix}${Math.abs(
        extractNumericValue(prevState.puntaje),
      ).toFixed(2)}`,
    }));
  };

  const handleFolioSelection = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setCatacion((prevState) => ({
      ...prevState,
      folioSecado: e.target.value.trim(),
      entradaId: selectedOption?.dataset?.entrada || prevState.entrada,
      productor: selectedOption?.dataset?.productor || prevState.productor,
      estado: selectedOption?.dataset?.estado || prevState.estado,
      region: selectedOption?.dataset?.region || prevState.region,
      municipio: selectedOption?.dataset?.municipality || prevState.municipio,
      comunidad: selectedOption?.dataset?.community || prevState.comunidad,
      tipo: selectedOption?.dataset?.tipo || prevState.tipo,
      caracteristica:
        selectedOption?.dataset?.caracteristica || prevState.caracteristica,
      claveDescarga:
        selectedOption?.dataset?.descarga || prevState.claveDescarga,
    }));
  };

  const handlePerfilRendering = (type, item) => {
    let flavor = item ? item : selectedFlavors[selectedFlavors.length - 1];

    switch (type) {
      case 'name': {
        return flavor?.nombre ? flavor.nombre : 'Nombre del perfil';
      }
      case 'hexcode': {
        return flavor?.hexcode ? flavor.hexcode : '#000';
      }
      case 'group': {
        const selectedFlavors = [
          flavor?.categoriaA,
          flavor?.categoriaB,
          flavor?.categoriaC,
          flavor?.categoriaD,
        ]
          .filter(Boolean)
          .join(', ');

        return selectedFlavors ? selectedFlavors : '-';
      }
      case 'type': {
        return flavor?.tipo ? flavor.tipo : '-';
      }
      default: {
        return '';
      }
    }
  };

  const handleKeyDown = (e) => {
    const currentValue = +catacion.puntaje;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setCatacion((prevState) => ({
        ...prevState,
        puntaje: `${prefix}${Math.abs(currentValue + 0.25).toFixed(2)}`,
      }));
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setCatacion((prevState) => ({
        ...prevState,
        puntaje: `${prefix}${Math.abs(currentValue - 0.25).toFixed(2)}`,
      }));
    }
  };

  const handleSelectByCommunity = () => {
    setCommunityModal({
      state: true,
      title: 'Selecciona una comunidad',
      subtitle: 'Los perfiles de esta comunidad serán asignados.',
      communities: perfilsByCommunity,
    });
  };

  return (
    <Card maxHeight="85vh" overflowY="auto">
      <Flex justify="space-between" px="10px" pt="5px" mb="25px" align="center">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Selección de perfil
        </Text>
      </Flex>
      <Tabs>
        <TabPanels mb="20px">
          <TabPanel p="0px">
            <Perfil
              name={handlePerfilRendering('name')}
              hexcode={handlePerfilRendering('hexcode')}
              group={handlePerfilRendering('group')}
              type={handlePerfilRendering('type')}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '1', lg: '1' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'2rem'}
        >
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <Tooltip label={CATACION.FOLIO_TOOLTIP} aria-label="A tooltip">
                <FormLabel
                  display="flex"
                  alignItems="center"
                  ms="4px"
                  fontSize="16px"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  gap="10px"
                >
                  Folio de secado <QuestionIcon color={textColor} />
                </FormLabel>
              </Tooltip>
            </Flex>
            <Select
              borderColor={
                isSubmitted && !catacion.folioSecado ? 'red.500' : null
              }
              value={catacion.folioSecado || ''}
              onChange={(e) => {
                handleFolioSelection(e);
              }}
              onFocus={handleFocus}
              variant="auth"
              placeholder="Folio de secado"
            >
              {secado.length > 0 &&
                secado.map((x) => (
                  <option
                    key={x.id}
                    value={x.id}
                    data-productor={x.productor || 'No especificado'}
                    data-estado={x.estado || 'No especificado'}
                    data-region={x.region || 'No especificado'}
                    data-municipality={x.municipio || 'No especificado'}
                    data-community={x.comunidad || 'No especificado'}
                    data-tipo={x.tipo || 'No especificado'}
                    data-caracteristica={x.caracteristica || 'No especificado'}
                    data-descarga={x.claveDescarga || ''}
                    data-entrada={x.entradaId || ''}
                  >
                    {x.id}
                  </option>
                ))}
            </Select>
            <FormLabel
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              ms="4px"
              fontSize="16px"
              fontWeight="500"
              color={textColor}
              mt="24px"
              mb="8px"
            >
              Puntaje del perfil
              <Flex gap="15px">
                <Text fontSize="12px" color={textColor}>
                  *Mínimo: 73.00
                </Text>
                <Text fontSize="12px" color={textColor}>
                  Máximo: 99.00
                </Text>
              </Flex>
            </FormLabel>
            <Flex>
              <Flex mr={2}>
                <Button
                  onClick={() => handlePrefixChange('+')}
                  bg={prefix === '+' ? buttonActiveBgColor : buttonBgColor}
                  color={buttonColor}
                  _hover={{ bg: buttonActiveBgColor }}
                  borderRadius="6px"
                >
                  Positivo +
                </Button>
                <Button
                  onClick={() => handlePrefixChange('-')}
                  bg={prefix === '-' ? buttonActiveBgColor : buttonBgColor}
                  color={buttonColor}
                  _hover={{ bg: buttonActiveBgColor }}
                  borderRadius="6px"
                  ml={2}
                >
                  Negativo -
                </Button>
              </Flex>
              <Input
                borderColor={
                  isSubmitted && !catacion.puntaje ? 'red.500' : null
                }
                value={catacion.puntaje}
                onChange={(e) => {
                  setCatacion((prevState) => ({
                    ...prevState,
                    puntaje: `${prefix}${extractNumericValue(
                      e.target.value.trim(),
                    )}`,
                  }));
                }}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                isRequired={true}
                fontSize="md"
                placeholder="Puntaje del perfil"
                variant="auth"
                size="lg"
              />
            </Flex>
          </Flex>
        </SimpleGrid>
      </FormControl>
      <Text fontSize="xl" color={textColor} fontWeight="700" mb="34px">
        Aromas | Sabores identificados
      </Text>
      {selectedFlavors?.length > 0 && (
        <Flex justify="center" mb={'15px'}>
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            loadingText="Realizando registro..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Registrar perfil
          </Button>
        </Flex>
      )}
      <Flex flexDirection="column" justify="flex-start">
        {selectedFlavors?.map((x) => {
          return (
            x.nombre &&
            x.hexcode && (
              <FlavorItem
                key={x.hexcode}
                mb="26px"
                name={x.nombre}
                specification={`${handlePerfilRendering(
                  'type',
                  x,
                )} | ${handlePerfilRendering('group', x)}`}
                selectedFlavors={selectedFlavors}
                setSelectedFlavors={setSelectedFlavors}
                icon={
                  <Icon
                    as={GiCoffeeBeans}
                    color={iconColor}
                    w="20px"
                    h="18px"
                  />
                }
              />
            )
          );
        })}
      </Flex>

      {selectedFlavors?.length === 0 && (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
          height="100%"
        >
          <Icon as={GiCoffeeBeans} color={iconColor} w="40px" h="40px" />
          <Text
            fontSize="sm"
            color={textColor}
            fontWeight="500"
            textAlign="center"
          >
            No hay valores registrados aún.
          </Text>
          <Button
            onClick={() => handleSelectByCommunity()}
            bg={buttonBgColor}
            color={buttonColor}
            _hover={{ bg: buttonActiveBgColor }}
            borderRadius="6px"
            mb="34px"
          >
            Seleccionar perfil por comunidad
          </Button>
        </Flex>
      )}
    </Card>
  );
}
