import React from 'react';
// Chakra imports
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  SimpleGrid,
  Text,
  Stack,
  Checkbox,
  useColorModeValue,
  InputRightAddon,
  Divider,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { MdCenterFocusWeak } from 'react-icons/md';
import { capitalizeEveryWord } from 'utils/format';
import { extractNumericValue } from 'utils/format';

export default function OrdenMaquila({
  ordenFolio,
  handleChange,
  isSubmitted,
  handleFocus,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdCenterFocusWeak color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Orden de maquila
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Tipo de maquila
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !ordenFolio.maquilaTipo ? 'red.500' : null
                }
                value={ordenFolio.maquilaTipo}
                onChange={(e) =>
                  handleChange('maquilaTipo', e.target.value.trim())
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Tipo de maquila"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Número ICO
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !ordenFolio.numeroIco ? 'red.500' : null
                }
                value={ordenFolio.numeroIco}
                onChange={(e) =>
                  handleChange('numeroIco', e.target.value.trim())
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Número ICO"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Tipo de preparación
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !ordenFolio.preparacion ? 'red.500' : null
                }
                value={ordenFolio.preparacion}
                onChange={(e) =>
                  handleChange('preparacion', e.target.value.trim())
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Tipo de preparación"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Cliente
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !ordenFolio.cliente ? 'red.500' : null
                }
                value={ordenFolio.cliente}
                onChange={(e) =>
                  handleChange('cliente', capitalizeEveryWord(e.target.value))
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Cliente"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Lote
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={isSubmitted && !ordenFolio.lote ? 'red.500' : null}
                value={ordenFolio.lote}
                onChange={(e) => handleChange('lote', e.target.value.trim())}
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Lote"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Contrato
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !ordenFolio.contrato ? 'red.500' : null
                }
                value={ordenFolio.contrato}
                onChange={(e) =>
                  handleChange('contrato', e.target.value.trim())
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Contrato"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <Divider orientation="horizontal" my={'2rem'} />
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <Text fontSize="md" fontWeight="500" color={textColor}>
              Limpio, en buen estado, sin olores extraños, sin materia extraña
              ni roturas, sin fauna nociva, etc.
            </Text>
          </Flex>
          <Stack spacing={5} direction="row">
            <Checkbox
              isChecked={ordenFolio.liberacionMateriaPrima === 'Cumple'}
              onChange={(e) => handleChange('liberacionMateriaPrima', 'Cumple')}
              color={textColor}
            >
              Cumple
            </Checkbox>
            <Checkbox
              isChecked={ordenFolio.liberacionMateriaPrima === 'No cumple'}
              onChange={(e) =>
                handleChange('liberacionMateriaPrima', 'No cumple')
              }
              color={textColor}
            >
              No cumple
            </Checkbox>
          </Stack>
          <Flex direction="row" alignItems="center" gap="30px">
            <Text fontSize="md" fontWeight="500" color={textColor}>
              Se requiere purga de tren
            </Text>
            <Checkbox
              isChecked={ordenFolio.purgaTren}
              onChange={(e) => handleChange('purgaTren', e.target.checked)}
              color={textColor}
            />
          </Flex>
        </SimpleGrid>
        <Divider orientation="horizontal" my={'2rem'} />
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Kilos a procesar
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !ordenFolio.kilogramos ? 'red.500' : null
                }
                value={ordenFolio.kilogramos}
                onChange={(e) =>
                  handleChange(
                    'kilogramos',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Kilogramos a procesar"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>KG</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Rendimiento exportable
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !ordenFolio.rendimientoExportable
                    ? 'red.500'
                    : null
                }
                value={ordenFolio.rendimientoExportable}
                onChange={(e) =>
                  handleChange(
                    'rendimientoExportable',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Rendimiento exportable"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>%</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Rendimiento total
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !ordenFolio.rendimientoTotal ? 'red.500' : null
                }
                value={ordenFolio.rendimientoTotal}
                onChange={(e) =>
                  handleChange(
                    'rendimientoTotal',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Rendimiento total"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>%</InputRightAddon>
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '1', md: '1', lg: '1' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          {' '}
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Observaciones
            </FormLabel>
            <InputGroup>
              <Input
                value={ordenFolio.observaciones}
                onChange={(e) =>
                  handleChange('observaciones', e.target.value.trim())
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Observaciones"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
