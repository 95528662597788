import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormLabel,
  FormControl,
  useDisclosure,
  Text,
  CircularProgress,
  Select,
  Flex,
} from '@chakra-ui/react';

import { useSelector, useDispatch } from 'react-redux';
import { addOrigen } from 'api/origen';
import ResultMessage from '../ResultMessage';
import { capitalizeEveryWord } from 'utils/format';
import { origenActions } from 'store/origen';

export default function RegisterMunicipality(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { origen } = useSelector((state) => state.origen);
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);

  const dispatch = useDispatch();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [origenObj, setOrigenObj] = useState({
    estado: null,
    region: null,
    municipio: null,
    comunidad: null,
  });

  const handleOrigen = (data, attribute, conditionName, conditionValue) => {
    const uniqueArray = [];
    const uniqueSet = new Set();

    if (data?.length > 0) {
      data.forEach((obj) => {
        const value = obj[attribute];

        if (value !== null && value && !uniqueSet.has(value)) {
          if (
            conditionName &&
            conditionValue &&
            conditionName !== conditionValue.toLowerCase() &&
            conditionValue !== 'No especificado'
          ) {
            if (obj[conditionName] === conditionValue) {
              uniqueSet.add(value);
              uniqueArray.push(value);
            }
          } else {
            uniqueSet.add(value);
            uniqueArray.push(value);
          }
        }
      });
      return ['No especificado', ...uniqueArray];
    } else {
      return uniqueArray;
    }
  };

  const [estado, setEstado] = useState([]);
  const [region, setRegion] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    setEstado(handleOrigen(origen, 'estado'));
    setRegion(handleOrigen(origen, 'region'));
  }, []);

  const handleCloseModal = () => {
    if (message.state === 'success') {
      onClose();
    }
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleChange = (field, value) => {
    setOrigenObj((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const selectedEstado = (estado) => {
    setRegion(handleOrigen(origen, 'region', 'estado', estado));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setIsSubmitted(true);
      const response = await addOrigen(TOKEN, USERID, origenObj);
      if (response.status === 200) {
        setIsLoading(false);
        dispatch(
          origenActions.setOrigenData({ origen: [...origen, origenObj] }),
        );
        handleMessage(
          'success',
          'Operación exitosa.',
          'La entrada fue registrada correctamente.',
        );
        window.scrollTo({ top: 0, left: 0 });
      } else {
        setIsLoading(false);
        handleMessage(
          'error',
          'Error en la operación.',
          'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
        );
      }
    } catch (error) {
      setIsLoading(false);
      handleMessage(
        'error',
        'Error en la operación.',
        'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
      );
      console.error(error);
    }
  };

  return (
    <>
      <Button
        bg={props.bgButtonColor}
        color="white"
        fontSize="11px"
        mb="8px"
        fontWeight="500"
        py="4px"
        height="auto"
        borderRadius="10px"
        onClick={onOpen}
      >
        + Añadir
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registrar nuevo municipio</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {!isLoading && !message.state && (
              <FormControl>
                <FormLabel mt="10px">Estado al que pertenece</FormLabel>
                <Select
                  borderColor={
                    isSubmitted && !origenObj.estado ? 'red.500' : null
                  }
                  value={origenObj.estado}
                  onChange={(e) => {
                    handleChange('estado', e.target.value);
                    selectedEstado(e.target.value);
                  }}
                  onFocus={handleFocus}
                  ref={initialRef}
                  variant="auth"
                  placeholder="Estado"
                >
                  {estado.length > 0 &&
                    estado.map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                </Select>
                <FormLabel mt="10px">Región a la que pertenece</FormLabel>
                <Select
                  borderColor={
                    isSubmitted && !origenObj.region ? 'red.500' : null
                  }
                  value={origenObj.region}
                  onChange={(e) => {
                    handleChange('region', e.target.value);
                  }}
                  onFocus={handleFocus}
                  variant="auth"
                  placeholder="Región"
                >
                  {region.length > 0 &&
                    region.map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                </Select>
                <FormLabel mt="10px">Nombre del municipio</FormLabel>
                <Input
                  borderColor={
                    isSubmitted && !origenObj.municipio ? 'red.500' : null
                  }
                  value={origenObj.municipio}
                  onChange={(e) =>
                    handleChange(
                      'municipio',
                      capitalizeEveryWord(e.target.value),
                    )
                  }
                  onFocus={handleFocus}
                  borderRadius="6px"
                  height="40px"
                  isRequired={true}
                  fontSize="md"
                  placeholder="Municipio"
                  variant="auth"
                  size="lg"
                />
              </FormControl>
            )}
            {isLoading && !message.state && (
              <Flex
                width={'100%'}
                height={'200px'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                gap={'10px'}
              >
                <Text>Cargando...</Text>
                <CircularProgress
                  isIndeterminate
                  color={props.bgButtonColor}
                  size={50}
                />{' '}
              </Flex>
            )}
            <ResultMessage
              isOpen={message.state}
              onClose={handleCloseModal}
              message={message}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleSubmit}
              bg={props.bgButtonColor}
              color="white"
              mr={3}
            >
              Guardar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
