import { useState } from 'react';
import {
  Divider,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  ModalBody,
  ModalHeader,
  Text,
  useColorModeValue,
  Button,
  Icon,
} from '@chakra-ui/react';
import { asignClient } from 'api/secado';
import { LiaHandshake, LiaCheckSolid } from 'react-icons/lia';
import { BiError } from 'react-icons/bi';
import { capitalizeEveryWord } from 'utils/format';
import { useSelector } from 'react-redux';
import IconBox from 'components/icons/IconBox';
import FolioHeader from 'components/modal/status/components/FolioHeader';

export default function AsignClient({
  message,
  setSelectedOption,
  setIsLoading,
  setConfirm,
  setStatusChanged,
}) {
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('brand.900', '#1B254B');
  const blueIcon = useColorModeValue('blue.500', 'white');
  const greenIcon = useColorModeValue('green.500', 'white');
  const redIcon = useColorModeValue('red.500', 'white');

  const TOKEN = useSelector((state) => state.auth.tokenId);
  const [selectedClient, setSelectedClient] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFocus = () => setIsSubmitted(false);

  const handleAsignClient = async () => {
    if (message.selectedFolio.id && selectedClient) {
      setIsLoading(true);
      try {
        const response = await asignClient(
          TOKEN,
          message.selectedFolio.id,
          selectedClient,
        );
        const isSuccess = response.status === 200;
        setStatusChanged(true);
        setConfirm({
          title: isSuccess ? 'Operación exitosa' : 'Error en operación',
          subtitle: isSuccess
            ? `El folio de café número ${message.selectedFolio.id} ha sido asignado al cliente '${selectedClient}'.`
            : 'Favor de intentar de nuevo o contactar a un agente de soporte.',
          icon: (
            <IconBox
              className="icon-box-maquila"
              h="42px"
              w="42px"
              bg={boxBg}
              icon={
                <Icon
                  as={isSuccess ? LiaCheckSolid : BiError}
                  color={isSuccess ? greenIcon : redIcon}
                  w="20px"
                  h="18px"
                />
              }
            />
          ),
        });
      } catch (error) {
        console.error(error);
        setConfirm({
          title: 'Error en operación',
          subtitle:
            'Favor de intentar de nuevo o contactar a un agente de soporte.',
          icon: (
            <IconBox
              className="icon-box-maquila"
              h="42px"
              w="42px"
              bg={boxBg}
              icon={<Icon as={BiError} color={redIcon} w="20px" h="18px" />}
            />
          ),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = () => {
    setSelectedOption('confirm');
    setConfirm({
      title: 'Asignar folio a cliente',
      subtitle: `¿Estás seguro de seleccionar el folio de café número ${message.selectedFolio.id} para muestra-oferta, asignado al cliente '${selectedClient}'?`,
      icon: (
        <IconBox
          className="icon-box-maquila"
          h="42px"
          w="42px"
          bg={boxBg}
          icon={<Icon as={LiaHandshake} color={blueIcon} w="20px" h="18px" />}
        />
      ),
      primaryBtnText: 'Confirmar',
      primaryBtnAction: handleAsignClient,
      secondaryBtnText: 'Volver',
      secondaryBtnAction: () => setSelectedOption(''),
    });
  };

  return (
    <ModalBody mb={10}>
      <FolioHeader message={message} />
      <Divider w="80%" alignSelf="center" margin="auto" />
      <ModalHeader
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        mt="20px"
        pb="0px"
        textAlign="center"
      >
        Asignación de cliente <LiaHandshake />
      </ModalHeader>
      <Flex
        justifyContent="center"
        alignItems="center"
        w="50%"
        mx="auto"
        mb={10}
      >
        <Text
          color="secondaryGray.600"
          fontSize="sm"
          fontWeight="500"
          textAlign="center"
        >
          Al asignar un cliente para muestra oferta, el folio de café
          seleccionado no estará disponible para maquilación o venta.
        </Text>
      </Flex>
      <Flex direction="column" w="60%" mx="auto">
        <FormLabel
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Cliente
        </FormLabel>
        <InputGroup>
          <Input
            borderColor={isSubmitted && !selectedClient ? 'red.500' : null}
            value={selectedClient}
            onChange={(e) =>
              setSelectedClient(capitalizeEveryWord(e.target.value))
            }
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Cliente"
            variant="auth"
            size="lg"
          />
        </InputGroup>
      </Flex>
      <Flex
        mt="50px"
        justifyContent="center"
        gap="20px"
        alignSelf="center"
        mx="auto"
      >
        <Button
          variant={'brand'}
          _hover={{ opacity: 0.8 }}
          onClick={handleSubmit}
        >
          Apartar muestra
        </Button>
        <Button
          variant={'lightBrand'}
          _hover={{ opacity: 0.8 }}
          onClick={() => setSelectedOption('')}
        >
          Volver
        </Button>
      </Flex>
    </ModalBody>
  );
}
