export const login = async (email, password) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/auth/login',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: email,
          password: password,
        }),
      },
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
