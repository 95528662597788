import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdCheckCircleOutline, MdOutlineHighlightOff } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

export default function ResultMessage({ isOpen, onClose, message }) {
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const navigate = useNavigate();

  const handleClick = (folio) => {
    navigate(`/admin/seco/maquila/orden-folio`, {
      state: { selectedFolio: folio },
    });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth={message?.folios ? '700px' : '400px'}>
        <ModalHeader
          display="flex"
          alignItems="center"
          gap="10px"
          pt="50px"
          textAlign="left"
        >
          {message.state === 'warning' && (
            <RiErrorWarningLine color={'#C27400'} size="25px" />
          )}
          {message.state === 'error' && (
            <MdOutlineHighlightOff color={'#E31A1A'} size="25px" />
          )}
          {message.state === 'success' && (
            <MdCheckCircleOutline color={'#5CB85C'} size="25px" />
          )}
          {message.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>{message.subtitle}</Text>
          {message?.folios?.length > 0 && (
            <Flex flexDirection="column" mt="25px">
              {message.folios.map((x) => (
                <Box
                  key={x.id}
                  borderRadius="8px"
                  backgroundColor={boxBg}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  mb="20px"
                  px="15px"
                  py="15px"
                  _hover={{
                    bg: balanceBg,
                    color: 'white',
                    transition: 'all 0.3s ease',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleClick(x)}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    width="80%"
                  >
                    <Text fontSize="md" fontWeight="700" flexBasis="15%">
                      Folio: {x?.folioSecado}
                    </Text>
                    <Text fontSize="md" fontWeight="500">
                      Perfil: {x?.flavorNames?.join(', ')}
                    </Text>
                  </Flex>

                  <ArrowForwardIcon color="currentColor" w="25px" h="auto" />
                </Box>
              ))}
            </Flex>
          )}
        </ModalBody>
        <ModalFooter gap="20px">
          <Button onClick={onClose}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
