import { useState } from 'react';
// Chakra imports
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { MdCenterFocusWeak } from 'react-icons/md';

import { QuestionIcon } from '@chakra-ui/icons';
import { MAQUILA, OFERTA } from 'constant/constant';

export default function MateriaPrima({
  secado,
  ordenFolio,
  setOrdenFolio,
  isSubmitted,
  handleFocus,
  handleMessage,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const [disabled, setDisabled] = useState(true);

  const handleFolioSelection = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setOrdenFolio((prevState) => ({
      ...prevState,
      folioSecado: e.target.value.trim(),
      entradaId: selectedOption?.dataset?.entrada || prevState.entrada,
      productor: selectedOption?.dataset?.productor || prevState.productor,
      estado: selectedOption?.dataset?.estado || prevState.estado,
      region: selectedOption?.dataset?.region || prevState.region,
      municipio: selectedOption?.dataset?.municipality || prevState.municipio,
      comunidad: selectedOption?.dataset?.community || prevState.comunidad,
      tipo: selectedOption?.dataset?.tipo || prevState.tipo,
      caracteristica:
        selectedOption?.dataset?.caracteristica || prevState.caracteristica,
      claveDescarga:
        selectedOption?.dataset?.descarga || prevState.claveDescarga,
    }));
    if (selectedOption?.dataset?.estatus === 'oferta') {
      handleMessage(
        OFERTA.WARNING_ESTATUS,
        OFERTA.WARNING_TITLE,
        OFERTA.WARNING_SUBTITLE,
      );
    }
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdCenterFocusWeak color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Referencias de la materia prima
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '4' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <Tooltip label={MAQUILA.FOLIO_TOOLTIP} aria-label="A tooltip">
                <FormLabel
                  display="flex"
                  alignItems="center"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  gap="10px"
                >
                  Folio de secado <QuestionIcon color={textColor} />
                </FormLabel>
              </Tooltip>
            </Flex>
            <Select
              borderColor={
                isSubmitted && !ordenFolio.folioSecado ? 'red.500' : null
              }
              value={ordenFolio.folioSecado || ''}
              onChange={(e) => {
                handleFolioSelection(e);
              }}
              onFocus={handleFocus}
              variant="auth"
              placeholder="Folio de secado"
            >
              {secado.length > 0 &&
                secado.map((x) => (
                  <option
                    key={x.id}
                    value={x.id}
                    data-productor={x.productor || 'No especificado'}
                    data-estado={x.estado || 'No especificado'}
                    data-region={x.region || 'No especificado'}
                    data-municipality={x.municipio || 'No especificado'}
                    data-community={x.comunidad || 'No especificado'}
                    data-tipo={x.tipo || 'No especificado'}
                    data-caracteristica={x.caracteristica || 'No especificado'}
                    data-descarga={x.claveDescarga || ''}
                    data-entrada={x.entradaId || ''}
                    data-estatus={x.estatus || ''}
                  >
                    {x.id}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Clave de descarga
            </FormLabel>
            <InputGroup>
              <Input
                disabled={disabled}
                borderColor={
                  isSubmitted && !ordenFolio.claveDescarga ? 'red.500' : null
                }
                value={ordenFolio.claveDescarga}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Clave de descarga"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Entrada
            </FormLabel>
            <InputGroup>
              <Input
                disabled={disabled}
                borderColor={
                  isSubmitted && !ordenFolio.entradaId ? 'red.500' : null
                }
                value={ordenFolio.entradaId}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Entrada ID "
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Productor
            </FormLabel>
            <InputGroup>
              <Input
                disabled={disabled}
                borderColor={
                  isSubmitted && !ordenFolio.productor ? 'red.500' : null
                }
                value={ordenFolio.productor}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Productor"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Región
            </FormLabel>
            <InputGroup>
              <Input
                disabled={disabled}
                borderColor={
                  isSubmitted && !ordenFolio.region ? 'red.500' : null
                }
                value={ordenFolio.region}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Región"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Municipio
            </FormLabel>
            <InputGroup>
              <Input
                disabled={disabled}
                borderColor={
                  isSubmitted && !ordenFolio.municipio ? 'red.500' : null
                }
                value={ordenFolio.municipio}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Municipio"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Tipo de café
            </FormLabel>
            <InputGroup>
              <Input
                disabled={disabled}
                borderColor={isSubmitted && !ordenFolio.tipo ? 'red.500' : null}
                value={ordenFolio.tipo}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Tipo de café"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Característica de café
            </FormLabel>
            <InputGroup>
              <Input
                disabled={disabled}
                borderColor={
                  isSubmitted && !ordenFolio.caracteristica ? 'red.500' : null
                }
                value={ordenFolio.caracteristica}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Característica de café"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
