import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  userPhone: '',
  userRole: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUserData(state, action) {
      state.userEmail = action.payload.userEmail;
      state.userFirstName = action.payload.userFirstName;
      state.userLastName = action.payload.userLastName;
      state.userPhone = action.payload.userPhone;
      state.userRole = action.payload.userRole;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
