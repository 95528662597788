import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import { PiTruckLight } from 'react-icons/pi';
import Buttons from 'views/admin/inventarios/folios/components/table/components/Buttons';
import styles from '../../../../../../assets/css/Table.module.css';

function Header(props) {
  const { tableMode, setTableMode } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  return (
    <Card bgColor={balanceBg} mb={{ base: '20px', xl: '0px' }} p="20px">
      <Flex bgColor={balanceBg} borderRadius="16px" direction="column" w="100%">
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          px="10px"
          className={styles.fixed_header}
        >
          <Flex gap="15px" alignItems="center">
            <Icon
              as={PiTruckLight}
              width="25px"
              height="25px"
              color={'white'}
            />
            <Text
              color={'white'}
              fontSize="lg"
              fontWeight="700"
              lineHeight="100%"
            >
              {tableMode} de café
            </Text>
          </Flex>
          <Buttons tableMode={tableMode} setTableMode={setTableMode} />
        </Flex>
      </Flex>
    </Card>
  );
}

export default Header;
