import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Change this to 'redux-persist/lib/storage' if you want to use localStorage
import errorSlice from './errors';
import authSlice from './auth';
import userSlice from './user';
import origenSlice from './origen';
import cafeSlice from './cafe';
import perfilSlice from './perfil';

// Combine all reducers
const rootReducer = combineReducers({
  errors: errorSlice,
  user: userSlice,
  auth: authSlice,
  origen: origenSlice,
  cafe: cafeSlice,
  perfil: perfilSlice,
});

// Configure the persistence for the root reducer
const persistConfig = {
  key: 'root', // This is the key under which your state will be saved in the storage
  storage, // This determines which storage to use, like localStorage or AsyncStorage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
