import { Flex, Text } from '@chakra-ui/react';
import { capitalizeEveryWord } from 'utils/format';

export default function FolioHeader({ message }) {
  return (
    <Flex
      direction="column"
      mt="25px"
      p="15px"
      w="80%"
      borderRadius="15px"
      alignSelf="center"
      mx="auto"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        gap="20px"
      >
        <Text fontSize="sm">Folio: {message?.selectedFolio?.id || '-'}</Text>
        <Text fontSize="sm">
          Productor: {message?.selectedFolio?.productor || '-'}
        </Text>
        <Text fontSize="sm">
          Peso:{' '}
          {message?.selectedFolio?.pergamino
            ? `${message.selectedFolio.pergamino}`
            : '0.00KG'}
        </Text>
        <Text fontSize="sm">
          Estatus: {capitalizeEveryWord(message?.selectedFolio?.estatus) || '-'}
        </Text>
      </Flex>
    </Flex>
  );
}
