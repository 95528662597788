export const tableColumnsRendimiento = [
  {
    headerName: 'Folio de secado',
    field: 'folio',
    width: 100,
  },
  {
    headerName: 'Entrada ID',
    field: 'entradaId',
    width: 100,
  },
  {
    headerName: 'Fecha',
    field: 'fecha',
    dataType: 'date',
    width: 200,
  },
  {
    headerName: 'Clave de descarga',
    field: 'claveDescarga',
    width: 200,
  },
  {
    headerName: 'Región',
    field: 'region',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Comunidad',
    field: 'comunidad',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Productor',
    field: 'productor',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Tipo',
    field: 'tipo',
    width: 200,
  },
  {
    headerName: 'Humedad',
    field: 'humedad',
    dataType: 'percentage',
    width: 200,
  },
  {
    headerName: 'Actividad de agua',
    field: 'actividadAgua',
    width: 200,
  },
  {
    headerName: 'Muestra pergamino',
    field: 'muestra',
    dataType: 'grams',
    width: 200,
  },
  {
    headerName: 'Café verde exportable',
    field: 'verdeExportable',
    dataType: 'grams',
    width: 200,
  },
  {
    headerName: 'Rendimiento exportable',
    field: 'rendimientoExportable',
    dataType: 'percentage',
    width: 200,
  },
  {
    headerName: 'Inferiores',
    field: 'inferiores',
    width: 200,
  },
  {
    headerName: 'Rendimiento inferiores',
    field: 'rendimientoInferior',
    dataType: 'percentage',
    width: 200,
  },
  {
    headerName: 'Café verde total',
    field: 'verdeTotal',
    dataType: 'grams',
    width: 200,
  },
  {
    headerName: 'Rendimiento total',
    field: 'rendimientoTotal',
    dataType: 'percentage',
    width: 200,
  },
  {
    headerName: 'Observaciones',
    field: 'observaciones',
    width: 200,
  },
];
