import {
  Button,
  Flex,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

import balanceImg from 'assets/img/dashboards/balanceImg.png';
import Card from 'components/card/Card.js';
import { IoAddCircleOutline } from 'react-icons/io5';
import { MdOutlineMonitorWeight } from 'react-icons/md';
import PesoForm from 'views/admin/seco/entradas/components/Peso por unidad o transporte/components/PesoForm';

export default function Peso({
  isSubmitted,
  handleFocus,
  handleWeightEntryChange,
  weightEntries,
  setWeightEntries,
  caracteristica,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const addEntry = () => {
    const nextInteger =
      weightEntries.length > 0
        ? Math.max(...weightEntries.map((entry) => entry.id)) + 1
        : 1;
    setWeightEntries([
      ...weightEntries,
      {
        id: nextInteger,
        chofer: 'Jorge Cruz Vázquez',
        placas: 'RY-30-570',
        marca: 'Ford',
        unidad: '1',
        cumple: 'Cumple',
        pesoConCarga: '0.00',
        pesoSinCarga: '0.00',
        yutes: '0',
        cajas: '0',
        lonas: '0',
        tara: '0',
        pesoBruto: '0.00',
        pesoNeto: '0.00',
        pesoPorSaco: '0.00',
        humedad: '0.00',
        descuentoHumedad: '0.00',
        descuentoHumedadPeso: '0.00',
        precioPrevio: '0.00',
        precioKg: '0.00',
        precioTotal: '0.00',
        caracteristica: '',
        ticketBascula: '',
        observaciones: '',
      },
    ]);
  };

  const deleteEntry = (idToDelete) => {
    setWeightEntries((prevState) => {
      const filteredWeightEntries = prevState.filter(
        (item) => item.id !== idToDelete,
      );
      const updatedWeightEntries = filteredWeightEntries.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      return updatedWeightEntries;
    });
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdOutlineMonitorWeight color="white" size={'25px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Peso por unidad o transporte
            </Text>
            <Text
              ms="auto"
              color={textColor}
              fontSize="sm"
              me="6px"
              fontWeight="700"
            ></Text>
          </Flex>
          <Tooltip hasArrow label="Añadir registro" bg="gray.300" color="black">
            <Button onClick={addEntry}>
              <IoAddCircleOutline fontSize="1.5rem" />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      {weightEntries.length > 0 &&
        weightEntries.map((x) => (
          <PesoForm
            key={x.id}
            id={x.id}
            weightEntry={x}
            isSubmitted={isSubmitted}
            handleFocus={handleFocus}
            handleWeightEntryChange={handleWeightEntryChange}
            deleteEntry={deleteEntry}
            caracteristica={caracteristica}
          />
        ))}
    </Card>
  );
}
