export const tableColumnsEntradaMUI = [
  {
    headerName: 'ID',
    field: 'registerId',
    width: 100,
  },
  {
    headerName: 'Fecha',
    field: 'fecha',
    dataType: 'date',
    width: 200,
  },
  {
    headerName: 'Peso neto',
    field: 'pesoNeto',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Precio por Kg',
    field: 'precioKg',
    dataType: 'pesos',
    width: 200,
  },
  {
    headerName: 'Precio Total',
    field: 'precioTotal',
    dataType: 'pesos',
    width: 200,
  },
];
