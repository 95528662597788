import React, { useEffect, useRef } from 'react';
import classes from '../css/FlavorWheel.module.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js/auto';

const FlavorWheel = ({ handleRenderFlavorItem }) => {
  const data = {
    datasets: [
      {
        labels: [
          'Floral',
          'Fragrante',
          'Cítrico',
          'Baya',
          'Cebolla',
          'Leguminoso',
          'Nuez',
          'Malta',
          'Dulce',
          'Jarabe',
          'Achocolatado',
          'Vainilla',
          'Trementina',
          'Medicinal',
          'Cálido',
          'Agudo',
          'Ahumado',
          'Ceniza',
          'Creosota',
          'Fenólico',
          'Cáustico',
          'Alkalino',
          'Astringente',
          'Áspero',
          'Neutro',
          'Seco',
          'Delicado',
          'Dulce',
          'Punzante',
          'Picante',
          'Acrimonioso',
          'Agridulce',
          'Duro',
          'Acre',
        ],
        backgroundColor: [
          '#008B8B',
          '#3CB371',
          '#607979',
          '#20B2AA',
          '#6380B8',
          '#2E8B57',
          '#4169E1',
          '#496989',
          '#194351',
          '#268566',
          '#3F5D77',
          '#87CEFA',
          '#116F6F',
          '#A8D9EC',
          '#485F5F',
          '#5F9EA0',
          '#71A6AD',
          '#25613F',
          '#4682B4',
          '#008080',
          '#87CEEB',
          '#51639A',
          '#5696D6',
          '#6F9DAC',
          '#49CDA1',
          '#20B2AA',
          '#9AC8E5',
          '#6B8E23',
          '#6495ED',
          '#2C5656',
          '#48A471',
          '#387C7D',
        ],
        data: [
          2.75, 2.75, 2.75, 2.75, 2.75, 2.75, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8,
          2.8, 2.8, 2.8, 2.75, 2.75,

          3.125, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125, 3.125,
          3.125, 3.125, 3.125, 3.125, 3.125, 3.125,
        ],
        hoverOffset: 14,
      },
      {
        labels: [
          'Acre',
          'Aspero',
          'Agudo',
          'Simple',
          'Terso',
          'Ácido',
          'Vinoso',
          'Agrio',
          'Floral',
          'Afrutado',
          'Herbal',
          'Nuez',
          'Acaramelado',
          'Achocolatado',
          'Resinoso',
          'Especies',
          'Carbón',
        ],
        backgroundColor: [
          '#A74C5A', // Rusty Pink
          '#A65279', // Berry Pink
          '#8C4676', // Wine Purple
          '#6D4269', // Plum Purple
          '#5A395B', // Aubergine
          '#4D3E4A', // Muted Plum
          '#A55470', // Rosy Pink
          '#944469', // Dusty Rose
          '#84546A', // Vintage Plum
          '#6E465A', // Deep Berry
          '#664E5A', // Mulberry
          '#583D55', // Dark Plum
          '#73455D', // Cranberry
          '#7C426C', // Bordeaux
          '#834C6D', // Amaranth Purple
          '#7B4164', // Magenta Purple
          '#8A5066', // Maroon
        ],
        data: [
          5.5, 5.5, 5.5, 5.6, 5.6, 5.6, 5.6, 5.6, 5.5, 6.25, 6.25, 6.25, 6.25,
          6.25, 6.25, 6.25, 6.25,
        ],
        hoverOffset: 14,
      },
      {
        labels: [
          'Enzimático',
          'Caramelización',
          'Destilación seca',
          'Amargo',
          'Salado',
          'Dulce',
          'Ácido',
        ],
        backgroundColor: [
          '#A52A2A',
          '#752323',
          '#8B4513',
          '#603000',
          '#8B4513',
          '#72481f',
          '#A08020',
        ],
        data: [16.5, 16.8, 16.7, 12.5, 12.5, 12.5, 12.5],
        hoverOffset: 14,
      },
      {
        labels: ['Aromas', 'Sabores'],
        backgroundColor: ['#4682B4', '#2D577E'],
        data: [50, 50],
        hoverOffset: 0,
      },
    ],
  };

  const config = {
    type: 'pie',
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const labelIndex = context.dataIndex;
              return ' ' + context.dataset.labels[labelIndex];
            },
          },
        },
        datalabels: {
          color: '#fff',
          formatter: (value, context) => {
            const labelIndex = context.dataIndex;
            return context.dataset.labels[labelIndex];
          },
        },
      },
      onClick: function (event, elements) {
        if (elements.length) {
          const segmentIndex = elements[0].index;
          const datasetIndex = elements[0].datasetIndex;
          const backgroundColor =
            data.datasets[datasetIndex].backgroundColor[segmentIndex];
          handleRenderFlavorItem(backgroundColor);
        }
      },
    },
  };

  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    Chart.register(ChartDataLabels);
    new Chart(ctx, config);
  }, []);

  return <canvas className={classes.canvas} ref={chartRef} />;
};

export default FlavorWheel;
