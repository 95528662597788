export const tableColumnsMaquila = [
  {
    headerName: 'ID',
    field: 'id',
    width: 100,
  },
  {
    headerName: 'Entrada',
    field: 'entradaId',
    width: 100,
  },
  {
    headerName: 'Folio',
    field: 'folioSecado',
    width: 200,
  },
  {
    headerName: 'Fecha',
    field: 'fecha',
    dataType: 'date',
    width: 200,
  },
  {
    headerName: 'Tipo maquila',
    field: 'tipoMaquila',
    width: 200,
  },
  {
    headerName: 'Número ICO',
    field: 'numeroIco',
    width: 200,
  },
  {
    headerName: 'Preparación',
    field: 'preparacion',
    width: 200,
  },
  {
    headerName: 'Cliente',
    field: 'cliente',
    width: 200,
  },
  {
    headerName: 'Rendimiento exportable',
    field: 'rendimientoExportable',
    dataType: 'percentage',
    width: 200,
  },
  {
    headerName: 'Rendimiento total',
    field: 'rendimiento Total',
    dataType: 'percentage',
    width: 200,
  },
  {
    headerName: 'Contrato',
    field: 'contrato',
    width: 200,
  },
  {
    headerName: 'Lote',
    field: 'lote',
    width: 200,
  },
  {
    headerName: 'Estado',
    field: 'estado',
    width: 200,
  },
  {
    headerName: 'Región',
    field: 'region',
    width: 200,
  },
  {
    headerName: 'Comunidad',
    field: 'comunidad',
    width: 200,
  },
  {
    headerName: 'Productor',
    field: 'productor',
    width: 200,
  },
  {
    headerName: 'Cosecha',
    field: 'cosecha',
    width: 200,
  },
  {
    headerName: 'Tipo',
    field: 'tipo',
    width: 200,
  },
  {
    headerName: 'Chofer',
    field: 'chofer',
    width: 200,
  },
  {
    headerName: 'Característica',
    field: 'caracteristica',
    width: 200,
  },
  {
    headerName: 'Observaciones',
    field: 'observaciones',
    width: 200,
  },
];
