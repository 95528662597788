// Chakra imports
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// React
import { useState } from 'react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { MdCenterFocusWeak } from 'react-icons/md';

export default function Descarga({
  entradas,
  secado,
  setSecado,
  isSubmitted,
  handleFocus,
  setSecadoEntries,
  latestId,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const [selectedEntries, setSelectedEntries] = useState(false);

  const uniqueEntradas = entradas.filter(
    (entry, index, self) =>
      index === self.findIndex((e) => e.entradaId === entry.entradaId),
  );

  const sumTotalBySpecificId = (specificId) => {
    const total = entradas
      .filter(
        (obj) =>
          +obj.entradaId === +specificId && !obj.registerId.includes('.0'),
      )
      .reduce(
        (accumulator, currentObject) => accumulator + +currentObject.pesoNeto,
        0,
      );

    return total;
  };

  const handleFolioWeights = (entradaId, totalWeight, totalFoliosSecado) => {
    setSecadoEntries(() => {
      let remainingWeight = totalWeight;

      const newSecadoEntries = Array.from(
        { length: totalFoliosSecado },
        (_, index) => {
          let folioWeight;
          if (index === totalFoliosSecado - 1) {
            folioWeight = remainingWeight;
          } else {
            folioWeight = Math.min(remainingWeight, 1000);
            remainingWeight -= folioWeight;
          }

          return {
            id: index + 1,
            folio: latestId + index + 1,
            claveDescarga: 'P-' + entradaId + '-' + (latestId + index + 1),
            peso: folioWeight.toFixed(2).toString(),
            pergamino: '',
            pergaminera: '',
            merma: '',
          };
        },
      );

      return newSecadoEntries;
    });
  };

  const handleSelectedEntries = (e, entry) => {
    const isChecked = e.target.checked;
    const excluidos = isChecked
      ? secado.excluidos.filter((id) => id !== entry.registerId)
      : [...secado.excluidos, entry.registerId];

    const totalWeight = isChecked
      ? (parseFloat(secado.pesoTotal) || 0) + (parseFloat(entry.pesoNeto) || 0)
      : (parseFloat(secado.pesoTotal) || 0) - (parseFloat(entry.pesoNeto) || 0);

    const totalFoliosSecado = Math.ceil(totalWeight / 1000);

    setSecado((prevState) => ({
      ...prevState,
      excluidos: excluidos,
      pesoTotal: totalWeight,
      foliosTotal: totalFoliosSecado,
    }));

    handleFolioWeights(entry.entradaId, totalWeight, totalFoliosSecado);
  };

  const handleEntradaSelection = (
    e = false,
    totalWeightInput = 0,
    totalFoliosSecadoInput = 0,
  ) => {
    const entradaId = e ? e.target.value.trim() : secado.entradaId;

    const selectedEntries = entradas.filter(
      (obj) =>
        +obj.entradaId === +entradaId &&
        !obj.registerId.includes('.0') &&
        obj.registerId.startsWith(entradaId + '.'),
    );

    setSelectedEntries(selectedEntries);

    const selectedOption = e ? e.target.options[e.target.selectedIndex] : false;
    const totalWeight = e
      ? sumTotalBySpecificId(entradaId)
      : totalWeightInput || secado.pesoTotal;
    const totalFoliosSecado = e
      ? Math.ceil(totalWeight / 1000)
      : totalFoliosSecadoInput ||
        (totalWeightInput ? secado.foliosTotal : null);

    setSecado((prevState) => ({
      ...prevState,
      pesoTotal: totalWeight,
      foliosTotal: totalFoliosSecado,
      entradaId: entradaId,
      productor: selectedOption?.dataset?.productor || prevState.productor,
      estado: selectedOption?.dataset?.estado || prevState.estado,
      region: selectedOption?.dataset?.region || prevState.region,
      municipio: selectedOption?.dataset?.municipality || prevState.municipio,
      comunidad: selectedOption?.dataset?.community || prevState.comunidad,
      tipo: selectedOption?.dataset?.tipo || prevState.tipo,
      caracteristica:
        selectedOption?.dataset?.caracteristica || prevState.caracteristica,
    }));

    handleFolioWeights(entradaId, totalWeight, totalFoliosSecado);
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdCenterFocusWeak color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Selección de entrada
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Entrada ID
              </FormLabel>
            </Flex>
            <Select
              borderColor={isSubmitted && !secado.entradaId ? 'red.500' : null}
              value={secado.entradaId || ''}
              onChange={(e) => {
                handleEntradaSelection(e);
              }}
              onFocus={handleFocus}
              variant="auth"
              placeholder="Entrada ID"
            >
              {uniqueEntradas.length > 0 &&
                uniqueEntradas.map((x) => (
                  <option
                    key={x.id}
                    value={x.entradaId}
                    data-productor={x.productor || 'No especificado'}
                    data-estado={x.estado || 'No especificado'}
                    data-region={x.region || 'No especificado'}
                    data-municipality={x.municipio || 'No especificado'}
                    data-community={x.comunidad || 'No especificado'}
                    data-tipo={x.tipo || 'No especificado'}
                    data-caracteristica={x.caracteristica || 'No especificado'}
                  >
                    {x.entradaId}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Kilogramos totales
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !secado.pesoTotal && !secado.entradaId
                    ? 'red.500'
                    : null
                }
                onChange={(e) => {
                  handleEntradaSelection(false, +e.target.value.trim(), 0);
                }}
                value={secado.pesoTotal}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Kilogramos totales"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Folios de secado generados
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !secado.foliosTotal && !secado.entradaId
                    ? 'red.500'
                    : null
                }
                onChange={(e) => {
                  handleEntradaSelection(false, 0, +e.target.value.trim());
                }}
                value={secado.foliosTotal}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Folios de secado"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Productor
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={
                  isSubmitted && !secado.productor ? 'red.500' : null
                }
                value={secado.productor}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Productor"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Región
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={isSubmitted && !secado.region ? 'red.500' : null}
                value={secado.region}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Región"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Municipio
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={
                  isSubmitted && !secado.municipio ? 'red.500' : null
                }
                value={secado.municipio}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Municipio"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '1' }}
          gap={{ sm: '10px', md: '26px' }}
          mt={'1rem'}
        >
          {secado.entradaId && (
            <Flex direction="column">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Subregistros seleccionados
              </FormLabel>
              <Stack spacing={5} direction="row">
                {selectedEntries?.map((entry) => (
                  <Checkbox
                    key={entry.registerId}
                    onChange={(e) => handleSelectedEntries(e, entry)}
                    isChecked={!secado.excluidos.includes(entry.registerId)}
                    color={textColor}
                  >
                    {entry.registerId} {`[${entry.pesoNeto} KG]`}
                  </Checkbox>
                ))}
              </Stack>
            </Flex>
          )}
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
