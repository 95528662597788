export const addCatacion = async (TOKEN, USERID, catacion) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/catacion/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          folioSecado: catacion.folioSecado,
          claveDescarga: catacion.claveDescarga.toString(),
          entradaId: catacion.entradaId,
          fecha: new Date(),
          perfiles: catacion.perfiles.toString(),
          puntaje: catacion.puntaje,
          estado: catacion.estado,
          region: catacion.region,
          municipio: catacion.municipio,
          comunidad: catacion.comunidad,
          productor: catacion.productor,
          tipo: catacion.tipo,
          cliente: catacion.cliente,
          caracteristica: catacion.caracteristica,
          clasificacion: catacion.clasificacion,
          createdAt: new Date(),
          createdBy: USERID,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllCatacion = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/catacion/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // prettier-ignore
          'Authorization': 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
