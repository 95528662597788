import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
	userId: '',
	tokenId: '',
};

const authSlice = createSlice({
	name: 'authentication',
	initialState: initialAuthState,
	reducers: {
		setUserId(state, action) {
			state.userId = action.payload;
		},
		setTokenId(state, action) {
			state.tokenId = action.payload;
		},
	},
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
