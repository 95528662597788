import { Tooltip, useColorModeValue } from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { esES } from '@mui/x-data-grid/locales';
import { useMemo, useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { MdPublishedWithChanges } from 'react-icons/md';
import { formatTableValue } from 'utils/format';

export default function DataTable({
  columns,
  rows,
  addActions = false,
  iconFunction,
  iconLabel,
  sortModel,
}) {
  const [pageSize, setPageSize] = useState(5);
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const textPrimaryColor = useColorModeValue('#1A202C', '#FFFF');
  const textSecondaryColor = useColorModeValue('gray.700', '#FFFF');
  const mainColor = useColorModeValue('#3a3a3c', '#FFFF');
  const secondaryColor = useColorModeValue('#ff9800', '#FFFF');
  const theme = createTheme({
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
            padding: '1rem',
            boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
            borderRadius: '2rem',
            backgroundColor: balanceBg,
            fontWeight: 600,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";',
          },
          columnHeaders: {
            color: textPrimaryColor,
            backgroundColor: balanceBg,
            fontWeight: 600,
            border: 'none',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: balanceBg,
            color: textPrimaryColor,
          },
        },
      },
    },
  });
  const processedRows = useMemo(() => {
    if (rows?.length > 0 && columns?.length > 0) {
      return rows.map((row) => {
        const formattedRow = { ...row };
        columns.forEach((column) => {
          if (formattedRow[column.field] !== undefined) {
            formattedRow[column.field] = formatTableValue(
              column.dataType,
              formattedRow[column.field],
            );
          } else {
            formattedRow[column.field] = '-';
          }
        });
        return formattedRow;
      });
    }
    return [];
  }, [rows, columns]);

  const processedColumns = useMemo(() => {
    if (addActions) {
      return [
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => {
            if (
              addActions === 'change status' &&
              (params?.row?.estatus.toLowerCase() === 'disponible' ||
                params?.row?.estatus.toLowerCase() === 'oferta')
            ) {
              return [
                <GridActionsCellItem
                  icon={
                    <Tooltip
                      label={iconLabel}
                      aria-label={iconLabel}
                      bg="black"
                      color="white"
                      padding="10px"
                      borderRadius="10px"
                    >
                      <span>
                        <MdPublishedWithChanges color={textPrimaryColor} />
                      </span>
                    </Tooltip>
                  }
                  label={iconLabel}
                  onClick={() => iconFunction(params)}
                />,
              ];
            } else if (addActions === 'see detail') {
              return [
                <GridActionsCellItem
                  icon={
                    <Tooltip
                      label={iconLabel}
                      aria-label={iconLabel}
                      bg="black"
                      color="white"
                      padding="10px"
                      borderRadius="10px"
                    >
                      <span>
                        <IoEyeOutline color={textPrimaryColor} />
                      </span>
                    </Tooltip>
                  }
                  label={iconLabel}
                  onClick={() => iconFunction(params)}
                />,
              ];
            }
            return [];
          },
        },
      ];
    }
    return columns;
  }, [addActions, iconLabel, iconFunction, columns]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGridPro
          rows={processedRows}
          columns={processedColumns}
          initialState={{
            sorting: {
              sortModel: sortModel || [],
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          headerFilters
          disableDensitySelector
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            '& .MuiDataGrid-container--top [role=row]': {
              backgroundColor: balanceBg,
              color: textPrimaryColor,
            },
            '& .MuiButtonBase-root.MuiIconButton-root': {
              color: textPrimaryColor,
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
}
