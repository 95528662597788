import {
  Checkbox,
  Flex,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { DRIVERS } from 'constant/driver';

import { capitalizeEveryWord } from 'utils/format';

export default function Vehiculo({
  id,
  weightEntry,
  isSubmitted,
  handleFocus,
  handleWeightEntryChange,
}) {
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        mb={'2rem'}
        px={'20px'}
      >
        <Flex alignItems="center" flexDirection="row" me="14px" gap="10px">
          <Text
            color="grey.500"
            fontSize="18px"
            fontWeight="700"
            lineHeight="100%"
          >
            Vehículo o transporte
          </Text>
        </Flex>
      </Flex>
      <SimpleGrid
        columns={{ base: '1', md: '3', lg: '4' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'20px'}
      >
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Operador{' '}
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <Input
            borderColor={isSubmitted && !weightEntry.chofer ? 'red.500' : null}
            value={weightEntry.chofer || ''}
            onChange={(e) =>
              handleWeightEntryChange(
                'chofer',
                capitalizeEveryWord(e.target.value),
                id,
              )
            }
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            isRequired={true}
            fontSize="md"
            placeholder="Operador"
            variant="auth"
            size="lg"
          />
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Placas{' '}
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <Select
            borderColor={isSubmitted && !weightEntry.placas ? 'red.500' : null}
            value={weightEntry.placas || ''}
            onChange={(e) => {
              let selectedVehicle = DRIVERS.find(
                (x) => x.placas === e.target.value,
              );
              handleWeightEntryChange('marca', selectedVehicle.marca, id);
              handleWeightEntryChange('placas', selectedVehicle.placas, id);
              handleWeightEntryChange('unidad', selectedVehicle.id, id);
            }}
            onFocus={handleFocus}
            variant="auth"
            placeholder="Placas"
          >
            {DRIVERS.map((x) => (
              <option key={x.id} value={x.placas}>
                {x.placas}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Marca{' '}
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <Select
            borderColor={isSubmitted && !weightEntry.marca ? 'red.500' : null}
            value={weightEntry.marca || ''}
            onChange={(e) => {
              let selectedVehicle = DRIVERS.find(
                (x) => x.marca === e.target.value,
              );
              handleWeightEntryChange('marca', selectedVehicle.marca, id);
              handleWeightEntryChange('placas', selectedVehicle.placas, id);
              handleWeightEntryChange('unidad', selectedVehicle.id, id);
            }}
            onFocus={handleFocus}
            variant="auth"
            placeholder="Marca"
          >
            {DRIVERS.map((x) => (
              <option key={x.id} value={x.marca}>
                {x.marca}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Número de unidad{' '}
            <Text color={brandStars} ml={'2px'}>
              {' '}
              *
            </Text>
          </FormLabel>
          <Select
            borderColor={isSubmitted && !weightEntry.unidad ? 'red.500' : null}
            value={weightEntry.unidad || ''}
            onChange={(e) => {
              let selectedVehicle = DRIVERS.find(
                (x) => x.id === e.target.value,
              );
              handleWeightEntryChange('marca', selectedVehicle.marca, id);
              handleWeightEntryChange('placas', selectedVehicle.placas, id);
              handleWeightEntryChange('unidad', selectedVehicle.id, id);
            }}
            onFocus={handleFocus}
            variant="auth"
            placeholder="Unidad"
          >
            {DRIVERS.map((x) => (
              <option key={x.id} value={x.id}>
                {x.id}
              </option>
            ))}
          </Select>
        </Flex>
        <Text fontSize="md" fontWeight="500" color={textColor}>
          Camión en condiciones de sanidad exterior e interior, en piso cubierta
          y techo
        </Text>
        <Stack spacing={5} direction="row">
          <Checkbox
            isChecked={weightEntry.cumple === 'Cumple'}
            onChange={(e) => handleWeightEntryChange('cumple', 'Cumple', id)}
            color={textColor}
          >
            Cumple
          </Checkbox>
          <Checkbox
            isChecked={weightEntry.cumple === 'No cumple'}
            onChange={(e) => handleWeightEntryChange('cumple', 'No cumple', id)}
            color={textColor}
          >
            No cumple
          </Checkbox>
        </Stack>
      </SimpleGrid>
    </>
  );
}
