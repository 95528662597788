import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';

export default function SelectCommunityPerfil({
  isOpen,
  onClose,
  communityModal,
  setSelectedFlavors,
  availableFlavors,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const [selectedFlavorsByCommunity, setSelectedFlavorsByCommunity] = useState(
    [],
  );

  const handleSelection = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    let communityFlavorsIds = selectedOption?.dataset?.perfiles
      ? selectedOption?.dataset?.perfiles.split(',')
      : [];
    let communityFlavorsObjs = availableFlavors.filter((x) =>
      communityFlavorsIds.includes(x.id.toString()),
    );
    setSelectedFlavorsByCommunity(communityFlavorsObjs);
  };

  const handleSubmit = () => {
    setSelectedFlavors(selectedFlavorsByCommunity);
    setSelectedFlavorsByCommunity([]);
    onClose();
  };

  const handleClose = () => {
    setSelectedFlavorsByCommunity([]);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="400px">
        <ModalHeader
          display="flex"
          alignItems="center"
          gap="10px"
          pt="50px"
          textAlign="center"
        >
          {communityModal.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>{communityModal.subtitle}</Text>

          <FormLabel mt="10px">Comunidad</FormLabel>
          <Select
            onChange={(e) => handleSelection(e)}
            variant="auth"
            placeholder="Comunidad"
          >
            {communityModal.communities.map((x) => (
              <option key={x.id} value={x.id} data-perfiles={x.perfiles || ''}>
                {x.comunidad}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter gap="20px">
          {selectedFlavorsByCommunity.length > 0 && (
            <Button color={'white'} bg={balanceBg} onClick={handleSubmit}>
              Seleccionar
            </Button>
          )}
          <Button onClick={handleClose}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
