// Chakra imports
import {
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { FaRegUser } from 'react-icons/fa';

export default function ClientePrintable({ salida, previewMode }) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const salidaVariables = [
    { title: 'Cliente', value: salida?.cliente || 'No especificado' },
    { title: 'Cosecha', value: salida?.cosecha || 'No especificado' },
    { title: 'Tipo de café', value: salida?.region || 'No especificado' },
    { title: 'Dirección', value: salida?.direccion || 'No especificado' },
    { title: 'Ciudad', value: salida?.ciudad || 'No especificado' },
  ];

  // Preview mode for "subregistros" values
  if (previewMode) {
    salidaVariables.push({
      title: 'Salida ID',
      value: salida?.registerId || 'No especificado',
    });
  }

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        borderWidth="2px"
        borderColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex gap="15px" flexDirection="row" me="14px">
            <FaRegUser color={balanceBg} size={'20px'} />
            <Text
              color={balanceBg}
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Datos del cliente
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '4', md: '4', lg: '4' }}
          gap={{ sm: '10px', md: '26px' }}
          px={'2rem'}
        >
          {salidaVariables.map((x) => (
            <Flex direction="column" mb={'1rem'}>
              <Flex>
                <FormLabel
                  display="flex"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  {x.title}
                </FormLabel>
              </Flex>
              <Text>{x.value}</Text>
            </Flex>
          ))}
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
