import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
// Custom components
import CenteredAuth from 'layouts/auth/types/Centered';

import { login } from 'api/user';
import { socket } from 'socket/socket';
import { authActions } from 'store/auth';
import { userActions } from 'store/user';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ state: false, message: '' });
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClick = () => setShow(!show);

  const handleError = (msg) => {
    setLoading(false);
    setError({
      state: true,
      message: msg,
    });
  };

  const handleFocus = () => {
    setError({
      state: false,
      message: '',
    });
    setIsSubmitted(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (email && password) {
      try {
        setLoading(true);
        const response = await login(email, password);
        switch (response?.status) {
          case 200: {
            const result = await response.json();
            setError({ state: false, message: '' });
            dispatch(authActions.setUserId(result.data.id));
            dispatch(authActions.setTokenId(result.data.token));
            navigate('/admin');
            socket.connect();
            dispatch(
              userActions.setUserData({
                userEmail: result.data.email,
                userFirstName: result.data.firstName,
                userLastName: result.data.lastName,
                userPhone: result.data.phone,
                userRole: result.data.role,
              }),
            );
            break;
          }
          case 400:
            handleError('El nombre de usuario o contraseña son incorrectos.');
            break;
          case 500:
          default:
            handleError('Error interno en el servidor.');
            break;
        }
      } catch (error) {
        console.error('Error:', error);
        handleError('Algo salió mal. Favor de volver a intentar.');
      }
    } else {
      handleError('Favor de llenar todos los campos solicitados.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <CenteredAuth
      cardTop={{ base: '140px', md: '14vh' }}
      cardBottom={{ base: '50px', lg: '50px' }}
      mx="0px"
    >
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        justifyContent="center"
        px={{ base: '20px', md: '0px' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Iniciar sesión
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Bienvenid@ de vuelta
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          {loading && (
            <Flex mb="5" justifyContent={'center'}>
              <CircularProgress isIndeterminate color={textColorBrand} />
            </Flex>
          )}
          {!loading && error.state && (
            <Alert mb="5" status="error">
              <AlertIcon />
              <AlertDescription>{error.message}</AlertDescription>
            </Alert>
          )}
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Correo electrónico<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              borderColor={isSubmitted && !email ? 'red.500' : null}
              value={email}
              onChange={handleEmailChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder="galgueragomez@gmail.com"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Contraseña <Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                borderColor={isSubmitted && !password ? 'red.500' : null}
                value={password}
                onChange={handlePasswordChange}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                isRequired={true}
                fontSize="sm"
                ms={{ base: '0px', md: '4px' }}
                placeholder="Min. 8 caracteres"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" align="center" mb="2rem">
              <FormControl display="flex" alignItems="center">
                <Checkbox id="remember-login" colorScheme="brand" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                  mb="0"
                >
                  Mantener sesión abierta
                </FormLabel>
              </FormControl>
              {/* <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  ¿Olvidaste tu contraseña?
                </Text>
              </NavLink> */}
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleSubmit}
            >
              Iniciar sesión
            </Button>
          </FormControl>
          {/* <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              ¿No te has registrado aún?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Crea una cuenta
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default SignIn;
