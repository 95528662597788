import {
  Button,
  Flex,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

import balanceImg from 'assets/img/dashboards/balanceImg.png';
import Card from 'components/card/Card.js';
import { IoAddCircleOutline } from 'react-icons/io5';
import { MdOutlineMonitorWeight } from 'react-icons/md';
import SalidaForm from 'views/admin/seco/salidas/components/SalidaForm';

export default function Salida({
  maquila,
  isSubmitted,
  handleFocus,
  handleWeightExitChange,
  weightExits,
  setWeightExits,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const addExit = () => {
    const nextInteger =
      weightExits.length > 0
        ? Math.max(...weightExits.map((entry) => entry.id)) + 1
        : 1;
    setWeightExits([
      ...weightExits,
      {
        id: nextInteger,
        folioMaquila: '',
        numeroIco: '',
        chofer: '',
        placas: '',
        marca: '',
        unidad: '',
        cumple: 'Cumple',
        sacosTotal: '',
        kilosBrutoTotal: '',
        kilosNetoTotal: '',
        precioLibra: '',
        observaciones: '',
      },
    ]);
  };

  const deleteExit = (idToDelete) => {
    setWeightExits((prevState) => {
      const filteredWeightExits = prevState.filter(
        (item) => item.id !== idToDelete,
      );
      const updatedWeightExits = filteredWeightExits.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      return updatedWeightExits;
    });
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdOutlineMonitorWeight color="white" size={'25px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Peso por unidad o transporte
            </Text>
            <Text
              ms="auto"
              color={textColor}
              fontSize="sm"
              me="6px"
              fontWeight="700"
            ></Text>
          </Flex>
          <Tooltip hasArrow label="Añadir registro" bg="gray.300" color="black">
            <Button onClick={addExit}>
              <IoAddCircleOutline fontSize="1.5rem" />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      {weightExits?.length > 0 &&
        weightExits.map((x) => (
          <SalidaForm
            key={x.id}
            id={x.id}
            maquila={maquila}
            weightExit={x}
            isSubmitted={isSubmitted}
            handleFocus={handleFocus}
            handleWeightExitChange={handleWeightExitChange}
            deleteExit={deleteExit}
            setWeightExits={setWeightExits}
          />
        ))}
    </Card>
  );
}
