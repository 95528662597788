// Chakra imports
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select,
  SimpleGrid,
  Text,
  Stack,
  Checkbox,
  useColorModeValue,
  Divider,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { MdCenterFocusWeak } from 'react-icons/md';
import { capitalizeEveryWord } from 'utils/format';

export default function Cliente({
  salida,
  isSubmitted,
  handleChange,
  handleFocus,
  tipo,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdCenterFocusWeak color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Datos del cliente y transporte
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Cliente
            </FormLabel>
            <Input
              borderColor={isSubmitted && !salida.cliente ? 'red.500' : null}
              value={salida.cliente || ''}
              onChange={(e) => handleChange('cliente', e.target.value)}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              isRequired={true}
              fontSize="md"
              placeholder="Cliente"
              variant="auth"
              size="lg"
            />
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Cosecha
            </FormLabel>
            <Input
              borderColor={isSubmitted && !salida.cosecha ? 'red.500' : null}
              value={salida.cosecha || ''}
              onChange={(e) => handleChange('cosecha', e.target.value)}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              isRequired={true}
              fontSize="md"
              placeholder="Cosecha"
              variant="auth"
              size="lg"
            />
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Tipo de café
            </FormLabel>
            <Select
              borderColor={isSubmitted && !salida.tipo ? 'red.500' : null}
              value={salida.tipo || ''}
              onChange={(e) => handleChange('tipo', e.target.value)}
              onFocus={handleFocus}
              variant="auth"
              placeholder="Tipo de café"
            >
              {tipo?.length > 0 &&
                tipo.map((x) => (
                  <option key={x.id} value={x.tipo}>
                    {x.tipo}
                  </option>
                ))}
            </Select>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '1', md: '2', lg: '2' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Dirección
            </FormLabel>
            <Input
              borderColor={isSubmitted && !salida.direccion ? 'red.500' : null}
              value={salida.direccion || ''}
              onChange={(e) => handleChange('direccion', e.target.value)}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              isRequired={true}
              fontSize="md"
              placeholder="Dirección"
              variant="auth"
              size="lg"
            />
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Ciudad
            </FormLabel>
            <Input
              borderColor={isSubmitted && !salida.ciudad ? 'red.500' : null}
              value={salida.ciudad || ''}
              onChange={(e) => handleChange('ciudad', e.target.value)}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              isRequired={true}
              fontSize="md"
              placeholder="Ciudad"
              variant="auth"
              size="lg"
            />
          </Flex>
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
