import React from 'react';

// Chakra imports
import { Flex, Text, useColorModeValue, Button } from '@chakra-ui/react';

// Custom components
import IconBox from 'components/icons/IconBox';
import { IoCloseCircleOutline } from 'react-icons/io5';
export default function FlavorItem(props) {
  const {
    specification,
    icon,
    name,
    selectedFlavors,
    setSelectedFlavors,
    ...rest
  } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconBoxBg = useColorModeValue('secondaryGray.300', 'navy.700');

  const handleDelete = () => {
    let editedFlavors = selectedFlavors.filter((obj) => obj.nombre !== name);
    setSelectedFlavors(editedFlavors);
  };

  return (
    <Flex justifyContent="center" alignItems="center" w="100%" {...rest}>
      <IconBox h="42px" w="42px" bg={iconBoxBg} me="20px" icon={icon} />
      <Flex direction="column" align="start" me="auto">
        <Text color={textColor} fontSize="md" me="6px" fontWeight="700">
          {name}
        </Text>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {specification}
        </Text>
      </Flex>
      <Text ms="auto" color={textColor} fontSize="sm" me="6px" fontWeight="700">
        <Button onClick={handleDelete}>
          <IoCloseCircleOutline fontSize="1.5rem" />
        </Button>
      </Text>
    </Flex>
  );
}
